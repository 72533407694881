// utils
import { dataManagement, getApiPrefix, server } from 'src/utils/api-prefix.helper';
import _ from 'lodash';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: 'SystemEntities',
	url: `${serverUrl}${dataManagement}/entities`,
	urlParams: '',
	path: '/system-entities',
	pageTitle: {
		list: 'System Entities',
		action: {
			create: 'New System Entity',
			edit: 'Edit System Entity',
		},
	},
	columns: [
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Type',
			field: 'type',
		},
		{
			headerName: 'Created by',
			field: 'created_by',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created at',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated by',
			field: 'updated_by',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Updated at',
			field: 'updated_at',
			type: 'datetime',
		},
	],
};
