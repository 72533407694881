// Dependencies
import React, { FC } from 'react';
// ant design
import { Tag } from 'antd';
//constant
import { stepsForFilter as WorkflowSteps } from 'ui/modules/Core/constants/workflow/workflow.constant';

interface IStep {
	id: string | number;
	title: string;
}

interface filterExecutionPathRendererProps {
	value: string | number;
}
const filterExecutionPathRenderer: FC<filterExecutionPathRendererProps> = ({ value }) => {
	const step = WorkflowSteps.find((step: IStep) => step.id === value);
	return (
		<span>
			<Tag style={{ textAlign: 'center' }}>{step ? step.title : value}</Tag>
		</span>
	);
};

export default filterExecutionPathRenderer;
