import { useContext, createContext } from 'react';

export const sideNavContext = createContext(null);

interface ISideNav {
	className: 'sidenav-collapsed' | 'sidenav-not-collapsed';
}

export function useSidenavContext(): ISideNav {
	return useContext(sideNavContext) as unknown as ISideNav;
}

export default useSidenavContext;
