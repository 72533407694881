import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
import configInterface from 'src/interface.config';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: 'AnchorStroke',
	pageTitle: {
		list: 'Freight',
		action: {
			edit: 'Freight Rate',
		},
	},
	path: '/freight',
	description: 'Freight',
	url: '',
	urlParams: '',
	noParams: true,
	voyages_list: {
		title: 'Voyages',
		path: '/freight/voyages',
	},
	new_voyage_input: {
		title: 'New voyage input',
		path: `/freight/voyages/${configInterface.newRecordUrlValue}`,
	},
	voyage_input: {
		title: 'Voyages input detail',
		path: `/freight/voyages/:voyageId/${configInterface.editRecordUrlValue}`,
	},
	forward_customization: {
		title: 'Forward customization',
		path: '/freight/voyages/:voyageId/forward_customization/:spotDate',
	},
	batches_list: {
		title: 'Batches',
		path: '/freight/batches',
	},
	batch_output: {
		title: 'Batches output detail',
		path: '/freight/batches/:id',
	},
	freight_voyages: {
		url: `${serverUrl}freight/voyages`,
	},
	calculateSpot: {
		url: `${serverUrl}freight/calculate`,
	},
	calculateRate: {
		url: `${serverUrl}freight/calculate-rate`,
	},
	vesselNames: {
		url: `${serverUrl}freight/vessels`,
	},
	portsInfos: {
		url: `${serverUrl}freight/ports`,
	},
	voyageInfos: {
		url: `${serverUrl}freight/voyageInfos`,
	},
	createVoyage: {
		url: `${serverUrl}freight/voyages`,
	},
	products: {
		url: `${serverUrl}freight/products`,
	},
	bunkers: {
		url: `${serverUrl}freight/bunkers`,
	},
	voyageResults: {
		url: `${serverUrl}freight/voyageResults/`,
	},
	stowageFactor: {
		url: `${serverUrl}freight/stowageFactor`,
	},
	outputSpotDates: {
		url: `${serverUrl}freight/output/spot-dates`,
	},
	outputCalculations: {
		url: `${serverUrl}freight/output/calculations/`,
	},
	batches: {
		url: `${serverUrl}freight/batches`,
	},
	forwardSpotDates: {
		url: `${serverUrl}freight/forward/spot-dates`,
	},
	forwardCustomization: {
		url: `${serverUrl}freight/voyages/:id/forward/calculations/`,
	},
	freight_estimates: {
		url: `${serverUrl}freight/estimates`,
	},
	estimate_infos: {
		url: `${serverUrl}freight/estimateInfos`,
	},
	freight_infos: {
		url: `${serverUrl}freight/infos`,
	},
	estimates_list: {
		title: 'Estimates',
		path: '/freight/estimates',
	},
	new_estimate_input: {
		title: 'New estimate input',
		path: `/freight/estimates/${configInterface.newRecordUrlValue}`,
	},
	estimate_edit: {
		title: 'Estimates update input',
		path: `/freight/estimates/:estimateId/${configInterface.editRecordUrlValue}`,
	},
};
