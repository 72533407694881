import { ISystemEntity } from 'ui/modules/Core/types/interfaces/system-entity.interface';

export const generateCode = (name: string) => {
	return name.toLowerCase().replace(/\s+/g, '_');
};

export const sortSystemEntities = (rowData: ISystemEntity[]) => {
	return (rowData || []).sort((a, b) => {
		if (a.type === 'business_category' && b.type !== 'business_category') {
			return 1; // Move `a` down
		}
		if (a.type !== 'business_category' && b.type === 'business_category') {
			return -1; // Move `b` down
		}
		return 0; // Keep order if both are the same
	});
};
