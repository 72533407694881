import React, { Component } from 'react';
import { isArray, values } from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import { PageHeader, Alert, Tooltip, Popover, Typography, Button, Space } from 'antd';
import Breadcrumb from 'ui/components/Nav/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import helpContent from 'help/support.json';
import { sideNavContext } from 'src/libs/SidenavContext';
import './OneColumnPage.sass';

export default class OneColumnPage extends Component {
	static contextType = sideNavContext;
	popoverContent() {
		if (this.props?.subModuleName && helpContent[this.props?.subModuleName]) {
			const article = helpContent[this.props?.subModuleName];

			return (
				<div className="pr-2" style={{ maxWidth: '50vw', maxHeight: '50vh', overflowY: 'auto' }}>
					<Typography.Title level={2}>{article.name}</Typography.Title>
					<div dangerouslySetInnerHTML={{ __html: article?.html }} />

					<Button
						className="d-block"
						type="secondary"
						href={article?.link}
						target="_blank"
						rel="noopener noreferrer"
					>
						Go to support center for details
						<FontAwesomeIcon className="ml-2" icon={faExternalLink} />
					</Button>
				</div>
			);
		}
	}

	render() {
		const helpContent = this.popoverContent();
		const { className } = this.context;
		return (
			<Container
				id={this.props.id}
				className={`OneColumnPage root_container ${className} ${this.props.className ? this.props.className : ''} ${this.props.usefixed ? ' use_fixed' : ''}`}
			>
				{this.props.title && !this.props.hideTitle ? (
					<>
						{this.props.breadcrumb === undefined || this.props.breadcrumb !== false ? (
							<Breadcrumb {...this.props} />
						) : null}
						<PageHeader
							className="pl-0 pr-0 pb-0 mb-1 pt-0"
							ghost
							title={
								<>
									<Tooltip title={this.props.tooltip || ''}>
										<div className="title-corr">
											{this.props.titleNoBreadcrumb
												? this.props.titleNoBreadcrumb
												: this.props.title}{' '}
										</div>
									</Tooltip>
									{helpContent ? (
										<Popover content={helpContent} placement="bottomLeft">
											<FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
										</Popover>
									) : (
										this.props.help
									)}
								</>
							}
							subTitle={
								<>
									{this.props.tag}
									{this.props.subtitle && !this.props.subtitle.match(/^(pages\.|model\.)/) ? (
										<>{this.props.subtitle}</>
									) : null}
									{this.props.secondSubtitle ? <span> | {this.props.secondSubtitle}</span> : ''}
								</>
							}
							extra={isArray(this.props.controls) ? this.props.controls : values(this.props.controls)}
						>
							{this.props.explanations && <Alert message={this.props.explanations} type="info" />}
						</PageHeader>
					</>
				) : (
					!this.props.noBreadCrumb && (
						<Space wrap className="w-100 d-flex align-item-center justify-content-between my-1">
							<div>
								{this.props.breadcrumb === undefined || this.props.breadcrumb !== false ? (
									<Breadcrumb noMargin {...this.props} />
								) : null}
							</div>
							<PageHeader
								className="pl-0 pr-0 pb-0 mb-0 pt-0"
								ghost
								title={null}
								subTitle={null}
								extra={isArray(this.props.controls) ? this.props.controls : values(this.props.controls)}
							/>
						</Space>
					)
				)}
				<Row>
					<Col>{this.props.children}</Col>
				</Row>
			</Container>
		);
	}
}
