// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// Ant design & Fontawesome
import { Button } from 'antd';
// Configs
import configForecast from 'ui/modules/Fundamentals/config/forecast.config';
// Helpers
import { useUserContext } from 'src/libs/contextLib';

const ForecastButton = () => {
	const { myPermissions } = useUserContext() as any;
	const { t: translate } = useTranslation();
	return (
		<Button
			onClick={() => {
				window.open(configForecast.path);
			}}
			key="forecast"
			disabled={!myPermissions?.forecast?.can_read}
		>
			{translate('model.forecast.name_plural')}
		</Button>
	);
};
export default ForecastButton;
