// helpers
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';

const serverUrl = `${server}${getApiPrefix()}/`;
export default {
	referenceData: false,
	url: `${serverUrl}dashboards`,
	iconName: 'Dashboard',
	basePath: '/dashboard',
	path: '/dashboard/list',
	parentField: 'parent_id',
	urlParams: '',
	pageTitle: {
		list: 'Dashboards',
		action: {
			create: 'New dashboard',
			edit: 'Edit dashboard',
		},
	},
	columns: [
		{
			headerName: 'Shared',
			field: 'shared',
			type: 'boolean',
			minWidth: 100,
			width: 100,
		},
		{
			headerName: 'Name',
			field: 'name',
			type: 'mergeEntity',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: false,
	canEdit: true,
	canDelete: false,
	canArchive: false,
	hasShared: true,
	sharedName: 'dashboard',
};
