// helpers
import DataService from 'src/utils/DataService';
import ConfigReport from 'ui/modules/Reporting/config/reporting.config';
import { INotebook } from 'ui/modules/Reporting/types/interfaces/report.interface';

export const open = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/open`,
		urlParams: '',
	}).post({});
};

export const close = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/force-close`,
		urlParams: '',
	}).post({});
};

export const publish = async (id: string, addToStorage: boolean) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/export${addToStorage ? '?storage=true' : ''}`,
		urlParams: '',
	}).post({});
};

export const save = async (id: string, payload: INotebook) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/save`,
		urlParams: '',
	}).put(payload);
};

export const saveAndClose = async (id: string, payload: INotebook) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/save-and-close`,
		urlParams: '',
	}).put(payload);
};

export default {
	open,
	close,
	publish,
	save,
	saveAndClose,
};
