import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { isArray } from 'lodash';
// Ant Design
import { Input, Form, Modal } from 'antd';
import { RuleObject } from 'antd/lib/form';
// Helpers & Utils
import { pushNotification } from 'src/utils/PushNotification';
import { useUserContext } from 'src/libs/contextLib';
// Interfaces
import { ITradeflow } from 'ui/modules/Core/types/interfaces/tradeflow.interface';
import { IProduction } from 'ui/modules/Fundamentals/Production/types/interfaces/production.interface';

type IRecord = ITradeflow | IProduction;

interface DuplicateRecordModalProps {
	duplicateService: (id: string, data: { code: string; value: string }) => Promise<void>;
	setIsOpenDuplicateModal: (v: boolean) => void;
	setLoader: (v: boolean) => void;
	isOpenDuplicateModal: boolean;
	refreshList: () => void;
	rowData: IRecord[];
	record: IRecord;
}

const DuplicateRecordModal: FC<DuplicateRecordModalProps> = ({
	setIsOpenDuplicateModal,
	isOpenDuplicateModal,
	duplicateService,
	refreshList,
	setLoader,
	rowData,
	record,
}) => {
	const { t: translate } = useTranslation();
	const { addToast } = useToasts();
	const { user } = useUserContext();
	const [form] = Form.useForm();

	useEffect(() => {
		if (isOpenDuplicateModal) {
			form.setFieldsValue({
				value: record?.name || '',
				code: record?.code ? record.code.replace(`${user.org}-`, '') + '-duplicated' : '',
			});
		}
	}, [isOpenDuplicateModal, record, form]);

	const confirmDuplication = (values: { code: string; value: string }) => {
		const code = values.code || `${record.code}-duplicated`;
		const name = values.value || `${record.name}-duplicated`;

		setLoader(true);
		pushNotification(addToast, translate(`model.list.infos.duplication`), 'info');

		duplicateService(record.id, { name, code: `${user.org}-${code}` })
			.then(() => {
				pushNotification(
					addToast,
					translate(`model.list.titles.duplication`),
					'success',
					translate(`model.list.success.duplicate_success`)
				);
				refreshList();
				setLoader(false);
				setIsOpenDuplicateModal(false);
			})
			.catch((err) => {
				pushNotification(
					addToast,
					translate(`model.list.titles.duplication`),
					'error',
					err?.response?.data?.message || translate(`model.list.errors.duplicate_error`)
				);
				setLoader(false);
			});
	};

	return (
		<Modal
			open={isOpenDuplicateModal}
			title={translate(`model.list.titles.duplicate_name`)}
			onOk={() => form.submit()}
			onCancel={() => setIsOpenDuplicateModal(false)}
			destroyOnClose
		>
			<Form form={form} layout="vertical" onFinish={confirmDuplication}>
				<Form.Item
					name="value"
					label={translate('form.name')}
					rules={[{ required: true, message: translate('model.list.errors.required_name') }]}
				>
					<Input placeholder={translate('model.list.placeholders.name')} />
				</Form.Item>
				<Form.Item
					name="code"
					label={translate('form.code')}
					rules={[
						{ required: true, message: translate('model.list.errors.required_code') },
						{
							validator: (_: RuleObject, value: string) => {
								if (!isArray(rowData)) return Promise.resolve();
								const fullCode = `${user.org}-${value}`;
								const isExist = rowData.some(
									(el) => el?.code?.toLowerCase() === fullCode.toLowerCase()
								);
								return isExist
									? Promise.reject(
											new Error(translate('modules.fundamentals.form.validators.duplicate_code'))
										)
									: Promise.resolve();
							},
						},
					]}
				>
					<Input
						placeholder={translate('model.list.placeholders.code')}
						addonBefore={`${user.org || 'empty'}-`}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default DuplicateRecordModal;
