import { createContext, useContext } from 'react';
import { IReferenceListContext } from 'src/types/interfaces/route.interface';

const ReferenceListContext = createContext({
	getReferenceListValues: (referenceListCode: string) => {},
	setReferenceListValues: (referenceListCode: string, values: any[]) => {},
	isReferenceListLoaded: (referenceListCode: string) => {},
	getReferenceLists: () => {},
});

export function useReferenceContext(): IReferenceListContext {
	return useContext(ReferenceListContext) as unknown as IReferenceListContext;
}
export default ReferenceListContext;
