// utils
import { getApiPrefix, server, dataManagement } from 'src/utils/api-prefix.helper';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	url: `${serverUrl}${dataManagement}/contracts`,
	iconName: 'Contracts',
	recordid: 'id',
	urlParams: '',
	path: '/contracts',
	parentPage: {
		name: 'contract',
	},
	pageTitle: {
		list: 'Contract',
		action: {
			create: 'Contract',
			edit: 'Edit Contract',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Start At',
			field: 'start_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'End At',
			field: 'end_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Updated At',
			field: 'updated_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Created At',
			field: 'created_at',
			type: 'datetime',
			minWidth: 200,
		},
	],
	canView: false,
	canCreate: false,
	canEdit: false,
	canDelete: false,
	canBulkExecute: false,
	useTab: 'general',
};
