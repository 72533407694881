import { INavItemProps } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
import config from 'src/config';
const configRecords = config.records;

// references children configuration
const referencesChildrenConfig = [
	{
		key: 'reference_data',
		description: 'pages.sidenav.references.reference_data',
		icon: configRecords.reference_data.iconName,
		path: configRecords.reference_data.path,
		is_child: true,
		permissionRecord: ['reference'],
	},
	{
		key: 'reference_management',
		description: 'pages.sidenav.references.reference_management',
		icon: configRecords.reference_management.iconName,
		path: configRecords.reference_management.path,
		is_child: true,
		permissionRecord: ['reference'],
	},
	{
		key: 'global-reference-mapping',
		description: 'pages.sidenav.references.global_reference_mapping',
		path: configRecords.dataset_global_mapping.path,
		icon: configRecords.dataset_global_mapping.iconName,
		is_child: true,
		permissionRecord: ['mapping'],
	},
	{
		key: 'dataset-reference-mapping',
		description: 'pages.sidenav.references.dataset_reference_mapping',
		path: configRecords.dataset_field_mapping.path,
		icon: configRecords.dataset_field_mapping.iconName,
		is_child: true,
		permissionRecord: ['mapping'],
	},
];

// fundamentals children configuration
const fundamentalsChildrenConfig = [
	{
		key: 'tradeflow',
		description: 'pages.sidenav.fundamentals.tradematrix',
		icon: configRecords.trade_flow.iconName,
		path: configRecords.trade_flow.path,
		is_child: true,
		module: ['tradeflows'],
		permissionRecord: ['trade_flow'],
	},
	{
		key: 'production',
		description: 'pages.sidenav.fundamentals.production',
		icon: configRecords.production.iconName,
		path: configRecords.production.path,
		is_child: true,
		module: ['production'],
		permissionRecord: ['production'],
	},
];

// dataset children configuration
const datasetsChildrenConfig = [
	{
		key: 'workflows',
		description: 'pages.sidenav.datasets',
		path: configRecords.dataset.path,
		icon: configRecords.dataset.iconName,
		module: ['datasets'],
		permissionRecord: ['dataset'],
		is_child: true,
	},
	{
		key: 'pipeline',
		description: 'pages.sidenav.dataprep',
		path: configRecords.pipeline.path,
		icon: configRecords.pipeline.iconName,
		module: ['pipeline'],
		permissionRecord: ['data_prep'],
		is_child: true,
	},
];

// storage children configuration
const storageChildrenConfig = [
	{
		key: 'email',
		description: 'pages.sidenav.storage.email',
		path: configRecords.email.path,
		icon: configRecords.email.iconName,
		is_child: true,
		permissionRecord: ['email'],
	},
	{
		key: 'email_ruleset',
		description: 'pages.sidenav.storage.email_ruleset',
		path: configRecords.email_ruleset.path,
		icon: configRecords.email_ruleset.iconName,
		is_child: true,
		permissionRecord: ['email_ruleset'],
	},
	{
		key: 'file',
		description: 'pages.sidenav.storage.files_storage',
		path: configRecords.files.path,
		icon: configRecords.files.iconName,
		is_child: true,
		permissionRecord: ['file'],
	},
];
//organization children configuration
const organizationChildrenConfig = [
	{
		key: 'organization',
		description: 'pages.sidenav.organization_control.organization_overview',
		path: configRecords.organization.path,
		icon: configRecords.organization.iconName,
		is_child: true,
		permissionRecord: ['organization'],
	},
	{
		key: 'resource_groups',
		description: 'pages.sidenav.organization_control.resource_groups',
		path: configRecords.resource_group.path,
		icon: configRecords.resource_group.iconName,
		is_child: true,
		permissionRecord: ['resource_group'],
	},
	{
		key: 'contracts',
		description: 'pages.sidenav.organization_control.contracts',
		path: configRecords.contract.path,
		icon: configRecords.contract.iconName,
		is_child: true,
		permissionRecord: ['contract'],
	},
	{
		key: 'users',
		description: 'pages.sidenav.organization_control.users',
		path: configRecords.user.path,
		icon: configRecords.user.iconName,
		is_child: true,
		permissionRecord: ['user'],
	},
];

//configuration children configuration
const settingsChildrenConfig = [
	{
		key: 'categories',
		description: 'pages.sidenav.environment_settings.categories',
		path: configRecords.category.path,
		icon: configRecords.category.iconName,
		is_child: true,
		permissionRecord: ['category'],
	},
	{
		key: 'label',
		description: 'pages.sidenav.environment_settings.labels',
		path: configRecords.label.path,
		icon: configRecords.label.iconName,
		is_child: true,
		permissionRecord: ['label'],
	},
	{
		key: 'script_library',
		description: 'pages.sidenav.environment_settings.script_library',
		path: configRecords.script_library.path,
		icon: configRecords.script_library.iconName,
		is_child: true,
		permissionRecord: ['script_library'],
	},
	{
		key: 'system_entity',
		description: 'pages.sidenav.environment_settings.system_entities',
		path: configRecords.system_entity.path,
		icon: configRecords.system_entity.iconName,
		is_child: true,
		permissionRecord: ['system_entity'],
	},
];
//combination of settings and organization children
const internalConfigurationChildrenConfig = [
	{
		key: 'organization_control',
		description: 'pages.sidenav.organization_control.title',
		children: organizationChildrenConfig,
		type: 'group',
		permissionRecord: ['user', 'organization', 'contract', 'resource_group'],
	},
	{
		key: 'settings',
		description: 'pages.sidenav.environment_settings.title',
		children: settingsChildrenConfig,
		type: 'group',
		permissionRecord: ['category', 'label', 'system_entity'],
	},
];

//sidebar configuration
export const sidebarConfig: INavItemProps[] = [
	{
		key: 'home',
		description: 'pages.sidenav.home',
		path: '/home',
		icon: 'Home',
		module: ['home'],
		permissionRecord: ['profile'],
	},
	{
		key: 'dashboard',
		description: 'pages.sidenav.dashboards',
		path: configRecords.dashboard.basePath,
		icon: configRecords.dashboard.iconName,
		module: ['dashboard'],
		permissionRecord: ['dashboard'],
	},
	{
		key: 'fundamentals',
		description: 'pages.sidenav.fundamentals.title',
		icon: 'Sheets',
		children: fundamentalsChildrenConfig,
		module: ['fundamentals'],
		permissionRecord: ['trade_flow', 'production'],
	},
	{
		key: 'freight',
		description: 'pages.sidenav.freight',
		icon: configRecords.freight.iconName,
		path: configRecords.freight.path,
		permissionRecord: ['freight'],
		module: ['freight'],
	},
	{
		key: 'dataset',
		description: 'pages.sidenav.datasets',
		icon: configRecords.dataset.iconName,
		children: datasetsChildrenConfig,
		module: ['datasets'],
		permissionRecord: ['dataset', 'data_prep'],
	},
	{
		key: 'storage',
		description: 'pages.sidenav.storage.title',
		icon: 'FilesManagement',
		children: storageChildrenConfig,
		module: ['storage'],
		permissionRecord: ['file', 'email', 'email_ruleset'],
	},
	{
		key: 'report',
		description: 'pages.sidenav.report',
		icon: configRecords.report.iconName,
		path: configRecords.report.path,
		permissionRecord: ['report'],
		module: ['report'],
	},
	{
		key: 'internal_configuration',
		description: 'Internal Configuration',
		icon: 'Settings',
		children: internalConfigurationChildrenConfig,
		module: ['organization', 'settings'],
		permissionRecord: ['user', 'organization', 'contract', 'resource_group', 'category', 'label', 'system_entity'],
	},
	{
		key: 'mapping',
		description: 'pages.sidenav.references.title',
		icon: 'References',
		children: referencesChildrenConfig,
		module: ['woto'],
		permissionRecord: ['mapping', 'reference'],
	},
];

// Business Categories Order
export const businessCategoriesOrder = [
	'*',
	'weather',
	'tradeflow',
	'grains_oilseeds',
	'sugar',
	'biofuels',
	'fertilizer',
	'livestock_and_meat',
	'eu_black_fryers',
	'admin',
];
