export default {
	name: 'Script library',
	name_plural: 'Script library',
	new_record_title: 'New Script library',
	tabs: {
		summary: 'Summary',
		versions: 'Versions',
		usages: 'Usages',
	},
	actions: {
		create_new: 'New Script',
		revert_script: 'Revert Script',
		view_script_version: 'View Script Version',
	},
	labels: {
		get_version: 'Retrieves script version',
		revert_version: 'Revert script version',
		get_usage: 'Retrieves script usage',
		description_guide: 'Your function call must follow this signature:',
		function_structure_guide: 'scriptLibraryName.functionName()',
		list_function_guide: 'The following helper functions are available:',
	},
	validations: {
		invalid_name: "Invalid name, mustn't contain numbers, spaces and special characters except for _",
	},
	messages: {
		revert_confirmation:
			'This action reverts the script version and updates the workflow script using it. Are you sure?',
		revert_warning: 'Please wait {{count}} second(s) before you can validate this action.',
		no_script_selected: 'No script library is selected',
		no_functions: 'This library has no functions',
	},
	success: {
		success_add_script: 'Script added successfully',
		success_update_script: 'Script updated successfully',
		success_delete_script: 'Script deleted successfully',
		success_revert_script_version: 'Script version reverted successfully',
	},
	errors: {
		error_get_script: 'An error occured while retrieving data',
		error_add_script: 'An error occurred while adding a new script',
		error_update_script: 'An error occurred while updating this script',
		error_delete_script: 'An error occurred while deleting this script',
		error_get_version: 'An error occured while retrieving version',
		error_revert_script_version: 'An error occured while reverting version',
		error_get_script_usage: 'An error occured while retrieving usage',
	},
	guide: {
		title: 'Script Library Guide',
	},
};
