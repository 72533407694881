import { getOrg } from 'src/utils/api-prefix.helper';
import configAuthentication from 'ui/modules/Authentication/config/auth.config';

export const decodeToken = (token: string) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

export const getInitialExpiredAt = () => {
	const decodedToken = getConnectedUser();
	const res = decodedToken && decodedToken.exp ? new Date(decodedToken.exp * 1000) : null;
	return res;
};

export const saveCurrentURL = () => {
	const currentURL = window.location.pathname;
	const org = getOrg();
	localStorage.setItem(`${org}-redirection`, currentURL);
};

export async function handleLogout(setIsAuthenticated: (val: boolean) => void, history: any, params: string = '') {
	setIsAuthenticated(false);
	const org = getOrg();
	localStorage.removeItem(`${org}.settings.references.reference_list_sync`);
	localStorage.removeItem('isAuthenticated');
	localStorage.removeItem('token');
	localStorage.removeItem('hide_twitter_ads');
	// do not redirect to login if path is password-recovery
	if (window.location.pathname !== `${configAuthentication.path}/password-recovery`) {
		history.push(`/login${params || ''}`);
	}
}

export const checkExpiredToken = (setIsAuthenticated: (val: boolean) => void, history: any) => {
	const initialToken = getInitialExpiredAt();
	if (initialToken) {
		const currentDate = new Date();
		const timeDifference = initialToken.getTime() - currentDate.getTime();

		if (timeDifference > 0) {
			return setTimeout(() => {
				saveCurrentURL();
				handleLogout(setIsAuthenticated, history, '?token=expired');
			}, timeDifference);
		}
	}
};

//redirection redirection 401 error
export async function redirectionUnauthorized() {
	saveCurrentURL();
	localStorage.removeItem('isAuthenticated');
	localStorage.removeItem('token');
	localStorage.removeItem('hide_twitter_ads');
	window.location.replace('/login?token=expired');
}

export const getConnectedUser = () => {
	const token = localStorage.getItem('token');
	if (token) {
		const decodedToken = decodeToken(token);
		return decodedToken || null;
	}
	return null;
};

export const getUserConnected = () => {
	const user = localStorage.getItem('user');
	return user ? JSON.parse(user) : null;
};

export const hasToken = () => {
	const token = localStorage.getItem('token');
	return token && JSON.parse(token)?.jwtToken;
};
