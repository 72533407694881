export default {
	home: 'Home',
	dashboards: 'Dashboards',
	datasets: 'Datasets',
	dataprep: 'Data Prep',
	workflows: 'Workflows',
	fundamentals: {
		title: 'Fundamentals',
		tradematrix: 'Tradematrix',
		production: 'Production',
		forecast: 'Forecast',
	},
	freight: 'Freight',
	references: {
		title: 'References',
		reference_data: 'Reference Data',
		reference_management: 'Reference Management',
		global_reference_mapping: 'Global Reference Mapping',
		dataset_reference_mapping: 'Dataset Reference Mapping',
	},
	organization_control: {
		title: 'Organization Control',
		organization_overview: 'Organization Overview',
		contracts: 'Contracts',
		users: 'Users',
		resource_groups: 'Resource Groups',
	},
	storage: {
		title: 'Files Management',
		email: 'Emails',
		email_ruleset: 'Email Rulesets',
		files_storage: 'Files Storage',
	},
	report: 'Reporting',
	environment_settings: {
		title: 'Environment Settings',
		categories: 'Categories',
		labels: 'Labels',
		system_entities: 'System Entities',
		script_library: 'Script Library',
	},
	api_documentation: 'API documentation',
	user_profile: 'User Profile',
	support: 'Support Guide',
	logout: 'Log Out',
	coming_soon: 'Coming soon',
	twitter: 'Twitter', //TODO: to be removed when deleting twitter module
};
