export default {
	trade_flows: [
		{ name: 'Partner', type: 'Location' },
		{ name: 'Partner_Name', type: 'Location' },
		{ name: 'Reporter_MappedCode', type: 'Location' },
		{ name: 'Reporter_MappedName', type: 'Location' },
		{ name: 'Product', type: 'Product' },
		{ name: 'Product_Name', type: 'Product' },
		{ name: 'Date Reporter', type: 'date' },
		{ name: 'Month', type: 'text' },
		{ name: 'MonthYear', type: 'text' },
		{ name: 'Year', type: 'text' },
		{ name: 'Forecast', type: 'boolean' },
		{ name: 'Quantity', type: 'numeric' },
		{ name: 'CropYear', type: 'text' },
		{ name: 'Group_Reporter', type: 'text' },
		{ name: 'Group_Partner', type: 'text' },
	],

	productions: [
		{ name: 'Date Reporter', type: 'date' },
		{ name: 'ProductCode', type: 'text' },
		{ name: 'ProductName', type: 'text' },
		{ name: 'LocationCode', type: 'text' },
		{ name: 'LocationName', type: 'text' },
	],

	forecasts_tradeflow: [
		{ name: 'Date', type: 'date' },
		{ name: 'Origin', type: 'Location' },
		{ name: 'Destination', type: 'Location' },
		{ name: 'Product', type: 'Product' },
		{ name: 'Quantity', type: 'numeric' },
		{ name: 'Version', type: 'numeric' },
		{ name: 'Cut-Off Date', type: 'date' },
	],
	forecasts_production: [
		{ name: 'Date', type: 'date' },
		{ name: 'Location', type: 'Location' },
		{ name: 'Product', type: 'Product' },
		{ name: 'Item', type: 'text' },
		{ name: 'Value', type: 'text' },
		{ name: 'Version', type: 'numeric' },
		{ name: 'Cut-Off Date', type: 'date' },
	],
};
