export default {
	name: 'Data Prep',
	name_plural: 'Data Prep',
	new_record_title: 'New Data Prep',
	actions: {
		create_new: 'Create a new Data Prep',
		add_scheduler: 'Add a new schedule',
	},
	placeholders: {
		select_tz: 'Select a timezone',
	},
};
