export default {
	health_check_issues: 'Healthcheck issues',
	all_health_check_issues: 'All healthcheck issues',
	failed_workflows: 'Failed workflows',
	succeeded_workflows: 'Succeeded Workflows',
	all_failed_workflows: 'All failed workflows',
	by_failed_step: 'By Failed Step',
	by_category: 'By Category',
	updated_datasets: 'Updated Datasets',
	last_updated_datasets: 'Last 5 updated Datasets',
	datasets: 'Datasets',
	emails_attachments: 'Emails & Attachments',
	datasets_progression: 'Datasets progression',
	evolution: 'Evolution',
	errors: {
		get_summaries: `Couldn't retrieve summaries information.`,
	},
	messages: {
		get_summaries: 'Get Summaries',
	},
};
