import React from 'react';
import { Tooltip, type MenuProps } from 'antd';
import { INavItemProps } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
import { Link } from 'react-router-dom';
import { IPermissions, IUser } from 'ui/modules/Core/types/interfaces/user.interface';
import _ from 'lodash';
import { History } from 'history';
import CustomIcon from 'ui/components/CustomIcon/CustomIcon';
import { Icons } from 'ui/types/enums/icons.enum';

type MenuItem = Required<MenuProps>['items'][number];

function addTitle(label: string, key: string, children?: MenuItem[], type?: 'group'): MenuItem {
	return {
		label,
		key,
		children,
		type,
	} as MenuItem;
}

export function getItem(
	inlineCollapsed: boolean,
	item: INavItemProps,
	history: History,
	translate: (text: string) => string,
	user: IUser,
	isAuthenticated: any,
	myPermissions: IPermissions
) {
	if (!hasPermission(myPermissions, item.permissionRecord)) {
		return;
	}
	let children: INavItemProps[] = item.children || [];
	const childrenList = children
		.filter((it) => !it.isNotVisible)
		.map((child) => {
			return getItem(inlineCollapsed, child, history, translate, user, isAuthenticated, myPermissions);
		}) as MenuProps['items'];

	const principalProperties = {
		key: item.key,
		description: item.description,
		path: item.path,
		icon: renderItem(item, translate, inlineCollapsed),
		disabled: (isAuthenticated && !user?.is_access_platform) || item.disabled,
	};
	return item.is_child
		? {
				...principalProperties,
				is_child: item.is_child ? item.is_child.toString() : null,
			}
		: {
				...principalProperties,
				module: item.module,
				type: item.type,
				...(childrenList &&
					childrenList?.length > 0 && {
						children: [addTitle(translate(item.description), `${item.key}-title`, childrenList, 'group')],
					}),
			};
}

const renderItem = (item: INavItemProps, translate: (text: string) => string, inlineCollapsed: boolean) => {
	const withTooltip = inlineCollapsed && !item.children ? true : false;
	return (
		<div key={item.key}>
			{
				//child case
				item.is_child ? (
					<Link to={item.path ? item.path : '#'} className="is-child">
						<CustomIcon icon={Icons[item.icon as keyof typeof Icons]} width={30} />
						<span className="m-2">{translate(item.description)}</span>
					</Link>
				) : (
					<>
						{withTooltip ? (
							<Tooltip title={translate(item.description)} key={item.key} placement={'right'}>
								{renderIcon(item)}
							</Tooltip>
						) : (
							renderIcon(item)
						)}
					</>
				)
			}
		</div>
	);
};

const renderIcon = (item: INavItemProps) => {
	return (
		<Link to={item.path ? item.path : '#'} className="is-parent">
			<CustomIcon icon={Icons[item.icon as keyof typeof Icons]} width={30} />
		</Link>
	);
};

//Check if user has permission to access this item
export const hasPermission = (myPermissions: IPermissions, records: string[]) => {
	return _.some(records, (item) => _.get(myPermissions, [item, 'can_read'], false));
};
