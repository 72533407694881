// dependencies
import Axios from 'axios';
import { saveAs } from 'file-saver';
import { orderBy, uniqBy } from 'lodash';
// utils and helpers
import { getTaskStatus } from 'utils/getResource';
import { sleep } from 'utils/Validator';
import { DataService } from 'src/utils';
import { fundamentals, getApiPrefix, server, dataManagement } from 'src/utils/api-prefix.helper';
// Interfaces
import { IPreviewPayload } from 'ui/modules/Core/types/interfaces/dataset.interface';
// Configs
import tradeflowConfig from 'src/ui/modules/Fundamentals/config/tradeflow.config';
// Constants
const serverUrl = `${server}${getApiPrefix()}/`;

export const getInstance = () => {
	return new DataService({
		url: `${tradeflowConfig.url}?old_sharing=true`,
		urlParams: '',
		token: true,
	});
};

export const bulkDelete = async (payload: { codes: string[] }) => {
	return await new DataService({
		url: `${tradeflowConfig.url}/delete`,
		urlParams: '',
	}).post(payload);
};

export const getOne = (code: string) => {
	return new DataService({
		url: `${tradeflowConfig.url}/`,
		urlParams: '',
		token: true,
	}).getClean(code);
};
export const updateOneTradeflowService = (code: string, params: any) => {
	return new DataService({
		url: `${tradeflowConfig.url}/`,
		urlParams: '',
		token: true,
	}).update(code, params);
};
export const createOneTradeflowService = (params: any) => {
	return new DataService({
		url: `${tradeflowConfig.url}/create`,
		urlParams: '',
		token: true,
	}).create(params);
};

export const downloadTradeflow = async (
	code: string,
	refresh = false,
	intradeflow = false,
	row = false,
	format = 'csv',
	rest = {},
	aggregations = false
) => {
	try {
		let result = await new DataService({
			url: `${tradeflowConfig.url}/${aggregations ? 'aggregations/' : ''}${code}/download`,
			urlParams: '',
			token: true,
		}).create({
			intraflows: {
				strategy: intradeflow ? 'include' : 'exclude',
			},
			row: row,
			refresh: !refresh,
			...rest,
			format: format,
		});

		const fileUrl = result.data.result.url;
		const task_id = result.data.task.id;
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});
			const blob = file.data;

			saveAs(blob, `${code}.csv`);

			return true;
		}
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const getTradeflowDataByCode = async (code: string, useCache = false, start_date = null) => {
	try {
		const result = await new DataService({
			url: `${tradeflowConfig.url}/${code}/get`,
			urlParams: '',
		}).create({
			useCache,
			intraflows: {
				strategy: 'include',
			},
			start_date: start_date,
		});

		const fileUrl = result.data.result?.result?.url;
		const task_id = result.data.result?.task?.id;
		const last_refreshed_at = result.data.result?.refreshed_at || new Date();
		if (!task_id) return { data: { url: result.data.result.url, task_id: null, refreshed_at: last_refreshed_at } };
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			return {
				data: {
					url: fileUrl,
					task_id: task_id,
					refreshed_at: last_refreshed_at,
				},
			};
		}
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const refreshTradeflowByCode = async (code: string) => {
	try {
		return await new DataService({
			url: `${tradeflowConfig.url}/${code}/refresh`,
			urlParams: '',
		}).create({
			useCache: false,
		});
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const getListTradeflows = async (exclude = [], selectFormat = false) => {
	let data = [];
	const result = await getInstance().getAll();
	data = result.data.result.filter((x: any) => ![exclude].includes(x.id));

	if (selectFormat) {
		data = data.map((el: any) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}
	return orderBy(data, 'name', 'asc');
};

// To be remove after removing old sharing
export const getAvailableTradeflows = async (id: string, selectFormat = false) => {
	const result = await getInstance().getAll();
	let data = result.data.result.filter((c: any) => c.id !== id);

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el: any) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}

	return uniqBy(data.flat(), 'code');
};

export const getMaxDates = async (datasets: []) => {
	const maxDate = await new DataService({
		url: `${serverUrl}${fundamentals}/about/publication-date`,
		urlParams: '',
	}).create({ datasets });
	return maxDate.data?.result || [];
};

export const getTradeflowPreview = async (code: string, payload: IPreviewPayload) => {
	const result = await new DataService({
		url: `${tradeflowConfig.url}/${code}/preview`,
		urlParams: '',
	}).create(payload);
	return result?.data || [];
};

// TODO: This should be migrated after providing access to snowflake from the vpc
export const getTradeflowDetails = async (payload: { code: string }) => {
	const result = await new DataService({
		url: `${tradeflowConfig.oldUrl}/sources-updates`,
		urlParams: '',
	}).create(payload);
	return result?.data || [];
};

export const getTradeflowSnapshots = async (code: string) => {
	const result = await new DataService({
		url: `${tradeflowConfig.url}/${code}/snapshots`,
		urlParams: '',
	}).getAll();
	return result?.data || [];
};

export const discoverSourceTradeflow = async (
	code: string,
	payload: {
		name: string;
		code: string;
		type: 'dataset' | 'forecast' | 'tradeflow';
		category: 'TF_CUSTOMS';
		filters: {
			max_date?: string;
			min_date?: string;
		};
		mode: 'Export' | 'Import';
		port: boolean;
	}
) => {
	return await new DataService({
		url: `${tradeflowConfig.url}/${code}/discover/source`,
		urlParams: '',
	}).create(payload);
};

export const discoverSourcesTradeflow = async (
	code: string,
	payload: {
		type: 'dataset' | 'forecast' | 'tradeflow';
		name: string;
		code: string;
		id: string;
		source: {
			id: string;
			code: string;
			name: string;
		};
		category: string;
	}[]
) => {
	return await new DataService({
		url: `${tradeflowConfig.url}/${code}/discover/sources`,
		urlParams: '',
	}).create(payload);
};

export const compareSnapshotsService = async (
	code: string,
	payload: {
		code: string;
		forecastsFrom: string[];
		forecastsTo: string[];
		from: string;
		to: string;
		startMonth: number;
	}
) => {
	return new DataService({
		url: `${tradeflowConfig.url}/${code}/compare`,
		urlParams: '',
	}).create(payload);
};

export const validateRows = async (
	tradeflowId: string,
	payload: {
		id: string;
		official_delay: string;
	}
) => {
	return new DataService({
		url: `${tradeflowConfig.url}/${tradeflowId}/trade_flow_location_period_dataset`,
		urlParams: '',
	}).put({ trade_flow_location_period_dataset: payload });
};

export const duplicate = async (
	tradeflowId: string,
	payload: {
		name: string;
		code: string;
	}
) => {
	return await new DataService({
		url: `${tradeflowConfig.url}/${tradeflowId}/duplicate`,
		urlParams: '',
	}).post({ ...payload });
};

const TradeflowService = {
	updateOneTradeflowService,
	compareSnapshotsService,
	getAvailableTradeflows,
	getTradeflowDataByCode,
	refreshTradeflowByCode,
	getTradeflowSnapshots,
	downloadTradeflow,
	getListTradeflows,
	validateRows,
	getMaxDates,
	getInstance,
	bulkDelete,
	duplicate,
	getOne,
};
export default TradeflowService;
