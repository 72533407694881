import { Space } from 'antd';
import React, { FC } from 'react';
// components
import CustomIcon from 'ui/components/CustomIcon/CustomIcon';
//constants
import { Icons } from 'ui/types/enums/icons.enum';
interface ColDef {
	iconName?: keyof typeof Icons;
	color?: string;
}
interface Data {
	approved: boolean;
	name: string;
}
interface TextWithIconRendererProps {
	value: string;
	data: Data;
	colDef: ColDef;
}
const TextWithIconRenderer: FC<TextWithIconRendererProps> = ({ value, ...props }) => {
	const { data, colDef } = props;
	if (!value) return null;

	return data.approved ? (
		<span>{data.name}</span>
	) : (
		<Space>
			{data.name}
			<CustomIcon
				icon={Icons[colDef.iconName as keyof typeof Icons] || Icons.Default}
				width={25}
				color={colDef.color}
			/>
		</Space>
	);
};

export default TextWithIconRenderer;
