import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';

// patterns
export const datePattern = /^(\d{4})([-/]?)(\d{2})\2(\d{2})$/;
export const yearPattern = /^\d{4}$/;
export const monthPattern =
	/^(jan(uary)?|feb(ruary)?|mar(ch)?|apr(il)?|may|jun(e)?|jul(y)?|aug(ust)?|sep(tember)?|oct(ober)?|nov(ember)?|dec(ember)?|[1-9]|0[1-9]|1[0-2])$/i;

// formats
const formats = [
	'YYYY-M-D',
	'YYYY-MM-DD',
	'YYYY/M/D',
	'YYYY/MM/DD',
	'M-D-YYYY',
	'MM-DD-YYYY',
	'MM/DD/YYYY',
	'DD-MM-YYYY',
	'DD/MM/YYYY',
	'DD M YYYY',
	'DD MM YYYY',
	'DD MMM YYYY',
	'DD MMMM YYYY',
];
const yearsFormats = ['YYYY'];
const monthsFormats = ['M', 'MM', 'MMM', 'MMMM'];

export const isDate = (dateString: string) => {
	const isValidDate = moment(dateString, formats, true).isValid();
	return isValidDate;
};

export const isYear = (dateString: string) => {
	const isYear = moment(dateString, yearsFormats).isValid();
	return isYear && yearPattern.test(dateString);
};

export const isMonth = (dateString: string) => {
	const isMonth = moment(dateString, monthsFormats).isValid();
	return isMonth && monthPattern.test(dateString);
};

export const getNowDate = () => {
	return moment().format('YYYY-MM-DD');
};

export const formatDate = (date: string | Date | Moment) => {
	return moment(date).format('YYYY-MM-DD');
};

export const timezoneOptions = momentTz.tz?.names().map((tz, index) => ({
	key: index,
	value: tz,
	label: tz,
}));
