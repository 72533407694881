export default {
	name: 'Estimate',
	name_plural: 'Estimates',
	list: 'Estimates', // on a ajouté ça pour ne change pas le name plural en général
	new_record_title: 'New Estimate',
	optimal: 'Optimal',
	actions: {
		create_new: 'Create new Estimate',
		edit: 'Edit freight rate',
		save: 'Save',
	},
	messages: {},
	tabs: {
		first: {
			title: 'Summary',
			sections: {
				general: 'General',
				template_fields: 'Template fields',
			},
			code: 'Code',
			steps: 'Steps',
			created: 'Created',
			updated: 'Updated',
			type: 'Type',
			filter_value: 'Filter value',
			filter_type: 'Filter type',
			mandatory: 'Mandatory',
		},
		second: {
			title: 'Related records',
			sections: {
				datasets: 'Datasets',
			},
			name: 'Name',
		},
	},
};
