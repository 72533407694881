// helpers
import { basePath, data, getApiPrefix, server, ingestWorkflow } from 'src/utils/api-prefix.helper';
// constants
const baseApiUrl = `${server}${basePath}/`;
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: 'Workflows',
	url: `${serverUrl}${data}/workflows`,
	oldUrl: `${baseApiUrl}workflow`,
	ingestWorkflowUrl: `${serverUrl}${ingestWorkflow}`,
	urlParams: 'relation=[dataset, scheduled_task, user]',
	path: '/workflows',
	title: 'model.workflow.types.ingest.name_plural',
	paramSearch: { hastobe: false, where: 'type eq ingest' },
	paramUrl: '/workflow',
	pageTitle: {
		list: 'Workflows',
		action: {
			create: 'New workflow',
			edit: 'Edit workflow',
		},
	},
	columns: [],
	canCreate: false,
	canEdit: true,
	canDelete: false,
	canExecuteWorkflow: true,
	showCheckBox: true,
	canChangeState: true,
	canChangeTags: true,
	loadLastUpdate: true,
};
