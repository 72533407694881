import configReferenceData from 'ui/modules/References/config/reference-data.config';
import configCompany from 'src/ui/modules/References/config/company.config';
import configCompanyGroup from 'src/ui/modules/References/config/company-group.config';
import configCompanyAndGroup from 'ui/modules/References/config/company-and-group.config';
import configCostProductionItem from 'src/ui/modules/References/config/cost-production-item.config';
import configCurrency from 'src/ui/modules/References/config/currency.config';
import configCustomCode from 'src/ui/modules/References/config/custom-code.config';
import configCustomCodeNotLinked from 'src/ui/modules/References/config/custom-code-not-linked.config';
import configDataType from 'src/ui/modules/References/config/data-type.config';
import configDeliveryTerm from 'src/ui/modules/References/config/delivery-term.config';
import configExchangeMonthReference from 'src/ui/modules/References/config/exchange-month-reference.config';
import configExchange from 'src/ui/modules/References/config/exchange.config';
import configLocation from 'src/ui/modules/References/config/location.config';
import configLocationGroup from 'src/ui/modules/References/config/location-group.config';
import configLocationAndGroup from 'src/ui/modules/References/config/location-and-group.config';
import configPeriodAggregationType from 'src/ui/modules/References/config/period-aggregation-type.config';
import configPriceType from 'src/ui/modules/References/config/price-type.config';
import configProductCategory from 'src/ui/modules/References/config/product-category.config';
import configProduct from 'src/ui/modules/References/config/product.config';
import configProductGroup from 'src/ui/modules/References/config/product-group.config';
import configProductAndGroup from 'src/ui/modules/References/config/product-and-group.config';
import configQuoteType from 'src/ui/modules/References/config/quote-type.config';
import configSndItem from 'src/ui/modules/References/config/snd-item.config';
import configSource from 'src/ui/modules/References/config/source.config';
import configTradeFlowMode from 'src/ui/modules/References/config/trade-flow-mode.config';
import configTransportCategory from 'src/ui/modules/References/config/transport-category.config';
import configTransportType from 'src/ui/modules/References/config/transport-type.config';
import configUnit from 'src/ui/modules/References/config/unit.config';
import configVegetationIndexVariable from 'src/ui/modules/References/config/vegetation-index-variable.config';
import configVesselStatus from 'src/ui/modules/References/config/vessel-status.config';

export default {
	reference_data: configReferenceData,
	company: configCompany,
	company_group: configCompanyGroup,
	cost_production_item: configCostProductionItem,
	currency: configCurrency,
	custom_code: configCustomCode,
	data_type: configDataType,
	delivery_term: configDeliveryTerm,
	exchange_month_reference: configExchangeMonthReference,
	exchange: configExchange,
	location_group: configLocationGroup,
	location: configLocation,
	period_aggregation_type: configPeriodAggregationType,
	price_type: configPriceType,
	product_category: configProductCategory,
	product_group: configProductGroup,
	product: configProduct,
	quote_type: configQuoteType,
	snd_item: configSndItem,
	source: configSource,
	trade_flow_mode: configTradeFlowMode,
	transport_category: configTransportCategory,
	transport_type: configTransportType,
	unit: configUnit,
	vegetation_index_variable: configVegetationIndexVariable,
	vessel_status: configVesselStatus,
	location_and_group: configLocationAndGroup,
	company_and_group: configCompanyAndGroup,
	product_and_group: configProductAndGroup,
	custom_code_not_linked: configCustomCodeNotLinked,
};
