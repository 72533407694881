export const server = 'https://api.dnext.io/';
export const basePath = 'dnext';
export const client = 'client';
export const version = 'v1.0';
export const beta = 'beta';
export const dev = 'dev';
export const data = 'data';
export const dataManagement = 'data-management';
export const ingestWorkflow = 'ingest-workflow';
export const referenceData = 'references';
export const fundamentals = 'fundamentals';
export const dataPrep = 'data-prep';
export const storage = 'storage';
export const scriptLibrary = 'script-library';

export const getOrg = () => {
	const hostName = document.location.hostname;
	return hostName === 'localhost' ? 'dndev' : hostName.replace('.dnext.io', '');
};

export const getApiPrefix = () => {
	const org = getOrg();

	if (org === 'dndev' || org === 'stage') {
		const defineEnv = getEnvDnext();
		if (!defineEnv) setEnvDnext(dev);
		return defineEnv;
	}
	if (org === 'dnexr') {
		return beta;
	}

	return version;
};

export const getEnvDnext = () => {
	return localStorage.getItem('envDnext') || 'dev';
};

export const setEnvDnext = (env: 'dev' | 'beta' | 'v1.0') => {
	localStorage.setItem('envDnext', env);
};

export const referenceDataUrl = `${server}${getApiPrefix()}/${referenceData}/`;
