import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import { pick } from "lodash";
import { Form, Input, Modal } from 'antd';

export class PromptModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};
	}

	render() {
		return (
			<Modal
				className="OperationSelectorModal"
				width="80vw"
				open
				onOk={() => {
					this.props.onHide();
					this.props.onConfirm(this.state);
				}}
				onCancel={this.props.onHide}
				title={this.props.t ? this.props.t(this.props.title) : this.props.title}
			>
				{this.props.message && <p>{this.props.message}</p>}
				{!this.props.onlyChildren && (
					<Form.Item label={this.props.label}>
						<Input
							type="text"
							onChange={(e) => this.setState({ value: e.target.value })}
							value={this.state.value}
						/>
					</Form.Item>
				)}
				{this.props.children &&
					this.props.children.map((i, index) => (
						<Form.Item key={index} label={i.label}>
							{React.cloneElement(i.input, {
								onChange: (e) => this.setState({ [i.name]: e.target ? e.target.value : e }),
							})}
						</Form.Item>
					))}
			</Modal>
		);
	}
}

PromptModal.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.string,
	onHide: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(PromptModal);
