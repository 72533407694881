// Dependencies
import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
// Ant design & FontAwesome Icons
import { Button, Dropdown, Menu, Space, Tooltip } from 'antd';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
// Services
import { updateWorkflowService } from 'ui/modules/Core/services/workflow.service';
// Helpers & Libs
import { useUserContext } from 'src/libs/contextLib';
// Interfaces
import { IDataset } from 'ui/modules/Core/types/interfaces/dataset.interface';

interface StateRendererProps {
	icons: Record<'runnable' | 'standby' | 'stopped', IconDefinition>;
	data: IDataset;
	value: string;
}

const StateRenderer: FC<StateRendererProps> = ({ value: propValue, data, icons }) => {
	const { t: translate } = useTranslation();
	const [loadingParameter, setLoadingParameter] = useState(false);
	const [value, setValue] = useState(propValue);
	const { myPermissions } = useUserContext() as any;

	const wfId = data?.workflow?.id;
	const disableState = !myPermissions?.workflow?.can_update;

	useEffect(() => {
		setValue(propValue);
	}, [propValue]);

	const handleChangeState = async (value) => {
		setLoadingParameter(true);
		setValue(value.key);
		await updateWorkflowService(wfId, { state: value.key });
		setLoadingParameter(false);
	};

	if (!value) return null;

	const menu = (
		<Menu onClick={(key) => handleChangeState(key)}>
			{Object.keys(icons).map((o) => (
				<Menu.Item key={o} icon={icons[o]} style={{ margin: '3px 0px' }} />
			))}
		</Menu>
	);

	return (
		<Space className="d-flex w-100 h-100 align-items-center justify-content-center">
			<Dropdown overlay={menu} trigger={['click']} disabled={disableState}>
				<Tooltip title={translate(`model.workflow.state.${disableState ? 'disbaled' : 'title'}`)}>
					<Button
						style={{ color: '#222222', width: '24px', height: '24px', lineHeight: '5px' }}
						loading={loadingParameter}
						size="small"
						onClick={(e) => e.preventDefault()}
						icon={icons[value] || '...'}
						className="no-margin-top d-flex justify-content-center align-items-center"
					/>
				</Tooltip>
			</Dropdown>
		</Space>
	);
};

export default StateRenderer;
