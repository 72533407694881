import React from 'react';
// Services
import { getProductionService } from 'src/ui/modules/Fundamentals/Production/services/production.service';
// Components
import ForecastButton from 'ui/modules/Fundamentals/components/ForecastButton/ForecastButton';
// Helpers
import { basePath, server } from 'src/utils/api-prefix.helper';

export default {
	cats: ['SND_ITEM'],
	referenceData: false,
	iconName: 'WheatEarStrokes',
	crud: `${server}dev/fundamentals/production/crud/`,
	url: `${server}${basePath}/production`,
	getService: () => ({
		instance: getProductionService,
	}),
	parentPage: {
		name: 'Fundamentals',
	},
	recordid: 'production_id',
	urlParams: '',
	path: '/fundamentals/production',
	pageTitle: {
		list: 'Production',
		action: {
			create: 'New production',
			edit: 'Edit production',
		},
	},
	actions: [<ForecastButton />],
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Shared',
			field: 'shared',
			type: 'boolean',
			minWidth: 100,
			width: 100,
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Last refreshed at',
			field: 'last_refreshed_date',
			type: 'datetime',
		},
		{
			headerName: 'Labels',
			field: 'labels',
			type: 'labels',
			minWidth: 200,
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: false,
	canbeCloned: false,
	canbeDuplicate: true,
	canbeSettings: true,
	canRefreshAndView: true,
	canAccessForecasts: true,
	canChangeTags: true,
};
