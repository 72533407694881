export default {
	sort: { name: 'Sort rows', description: 'Sort the dataset based on some columns' },
	keep_top: { name: 'Keep top rows', description: 'Keep the first N rows as specified by the parameter' },
	keep_bottom: { name: 'Keep bottom rows', description: 'Keep the last N rows as specified by the parameter' },
	keep_range: {
		name: 'Keep range rows',
		description:
			'Keep the N rows as specified by the second parameter, starting from the row specified by the first parameter',
	},
	fill_down: { name: 'Fill down', description: 'Fill all the empty values with the last non-empty value' },
	replace_value: {
		name: 'Replace value',
		description: 'Replace values from a column when it matches a specific value and convert to text',
	},
	conditional_value: {
		name: 'Conditional value',
		description: 'Modify the value from a column based on a condition',
	},
	alter_columns_values: {
		name: 'Alter columns values',
		description: 'Alter or create columns values based on conditions',
	},
	select_hierarchy: {
		name: 'Select hierarchy',
		description: 'Select the hierarchy of a reference',
	},
	merge_header: { name: 'Merge header', description: 'Merge the first row with the header' },
	set_value: { name: 'Set value', description: 'Replace a value from one column' },
	set_values: { name: 'Set values', description: 'Replace all values from one or multiple columns' },
	set_types: { name: 'Change column types', description: 'Define the types of the different columns' },
	unpivot: { name: 'Unpivot', description: 'Unpivot columns' },
	convert_type_object: {
		name: 'Extract entity',
		description: 'Extract entity from object of the column',
	},
	extract_string: { name: 'Extract string', description: 'Extract string from column' },
	select_columns: { name: 'Select columns', description: 'Keep only indicated columns' },
	concat_column: { name: 'Concat column', description: 'Merge multiple column in one new column' },
	remove_columns: { name: 'Remove columns', description: 'Remove the columns of the list' },
	remove_bottom: { name: 'Remove bottom Rows', description: 'Remove the first N rows as specified by the parameter' },
	split_string: {
		name: 'Split string',
		description: 'Split a string based on the provided arguments. Splited element will create columns',
	},
	remove_top: { name: 'Remove top Rows', description: 'Remove the first N rows as specified by the parameter' },
	rename_column: { name: 'Rename column', description: 'Changes the name of a column by a new one' },
	rename_columns: { name: 'Rename columns', description: 'Changes the name of multiple columns by a new one' },
	remove_duplicates: {
		name: 'Remove duplicates',
		description: 'Remove the rows for the entire dataset when duplicates are found in the column specified',
	},
	add_index: { name: 'Add index', description: 'Add a column with an index starting from 0 or 1' },
	filter: { name: 'Filter', description: "List of conditions with 'AND' or 'OR' clauses" },
	transform_string: {
		name: 'Transform string',
		description: 'Transform a string by applying basic functions like upper, lower, trim, etcâ€¦',
	},
	append_rows: { name: 'Append rows', description: 'Append datasets rows' },
	merge_datasets: {
		name: 'Merge datasets',
		description: 'Merge different datasets by adding them as new columns',
		alert: 'You should add at least one column for each dataset to be merged!',
	},
	group_by: { name: 'Group by', description: 'Group element by column(s)' },
	operations_column: {
		name: 'Operation column',
		description: 'Update a column and apply your own numerical operation',
	},
	delete_empty_rows: { name: 'Delete empty rows', description: 'Delete the rows that are empty' },
	alter_date: { name: 'Alter date', description: 'Alter a date by adding or removing days, months, years' },
	change_frequency: {
		name: 'Change frequency',
		description: 'Changes the frequency of a specific temporal column and aggregates/split a numerical value',
	},
	replace_descendants: {
		name: 'Replace descendants',
		description: 'Replace all desendants of a reference by the reference',
	},
	split_groups: {
		name: 'Split groups',
		description: 'Creates as many records as group members, split values across group members',
	},
	date_extractor: {
		name: 'Date extractor',
		description: 'Extract unit from a date and create a new column with the date',
	},
	replace_part_of_string: {
		name: 'Replace part of string',
		description: 'Replace part of string by another string',
	},

	default: { name: 'Default', description: 'Default' },
};
