export default {
	name: 'Dataset',
	name_plural: 'Datasets',
	new_record_title: 'New dataset',
	description: 'Owned by your organization or shared with it',

	actions: {
		search: 'Search',
		create_new: 'Create a new dataset',
		create: 'Create dataset',
		clone: 'Clone dataset',
		upload_new_version: 'Upload new version',
		empty: 'Erase data',
		revert: 'Revert data to this version',
		clone_and_go_to_dataset: 'Clone and go to dataset details',
		clone_and_setup_workflow: 'Clone and setup a workflow immediately',
		update_dataset_sources: 'Update dataset sources',
	},
	messages: {
		error_get_datasets: 'An error occurred while retrieving datasets',
		empty_confirmation: 'This will remove all rows inside your dataset and create a new version. Are you sure?',
		revert_confirmation: 'This will change all the dataset content and create a new version. Are you sure?',
		empty_warning: 'Please wait {{count}} second(s) before you can validate this action.',
		revert_warning: 'Please wait {{count}} second(s) before you can validate this action.',
		empty: 'Dataset has been emptied',
		success_revert: 'Dataset has been reverted to an older version',
		error_revert: 'An error occured while reverting dataset to an older version',
		success_erase: 'Dataset has been erased successfully',
		error_erase: 'An error occured while erasing dataset',
		success_download: 'Dataset has been downloaded successfully',
		error_download: 'An error occured while downloading dataset',
		error_empty_dataset: "The dataset may be empty or you don't have the right to read it",
		reapply_mapping: 'The dataset has been modified with a reapply mapping',
		reference_replace: 'The dataset has been modified with a reference replace',
		success_create_dataset: 'The dataset created successfully',
		error_create_dataset: 'An error occured while adding the dataset',
		success_update_dataset: 'The dataset has been updated successfully',
		error_update_dataset: 'An error occured while updating the dataset',
		success_ignore_healthcheck_violation: 'The healthcheck violations have been successfully ignored',
		error_ignore_healthcheck_violation: 'An error occured while ignoring the healthcheck violations',
		no_version_error_update_dataset:
			'Compare with previous version and Compare with previous date cannot be enabled when the dataset have no versions',
		success_clone_dataset: 'The dataset cloned successfully',
		error_clone_dataset: 'An error occured while cloning the dataset',
		success_update_dataset_source: 'Dataset sources updated successfully',
		empty_mapping_values_dataset: 'There are currently no mappings related to this dataset.',
		error_get_health_check: 'An error occured while getting dataset health check values',
		empty_healthcheck_values_dataset: 'There are currently no healthcheck values related to this dataset.',
		grade_of_shared: 'Shared dataset grade is a private information',
		no_grade: 'No grade found for this dataset',
	},
	validations: {
		valid_name: 'Name is required',
		valid_code: 'Code is required',
		invalid_code: "Invalid code, mustn't contain spaces and special characters except for -",
		valid_category: 'Category is required',
		valid_product: 'Product is required',
		valid_product_category: 'Product Category is required',
		valid_data_steward: 'Data steward is required',
		valid_source: 'Source is required',
		valid_data_owner: 'Data Owner is required',
		valid_data_channel: 'Data Channel is required',
		valid_compliance: 'Compliance level is required',
		valid_location: 'Location is required',
		valid_location_group: 'Location Group is required',
		valid_frequency: 'Frequency is required',
	},
	payload: {
		fields: {
			created_at: 'Created at',
			type: 'Type',
			download_format: 'Format',
		},
		context: {
			file_name: 'File name',
		},
	},
	statistics: {
		rows_count_added: 'New rows count',
		rows_count_removed: 'Lost rows',
		rows_count_after: 'Final rows count',
		rows_count_before: 'Historical rows count',

		new_rows_count: 'New rows count',
		new_rows_count_short: 'New',

		rows_count: 'Final rows count',
		rows_count_short: 'Final',

		removed_rows_count: 'Lost rows count',
		removed_rows_count_short: 'Lost',

		net_rows_count_change: 'Net rows count growth',
		net_rows_count_change_short: 'Net growth',

		historical_rows_count: 'Historical rows count',
		historical_rows_count_short: 'Historical',

		kept_rows_count: 'Kept',
		existing_rows_count: 'Existing',

		no_statistics: 'No statistics',
		empty: 'Empty',
		filled: 'Filled',
		collapse_rows: 'Collapse Rows',
		total_row: 'Total Rows',
	},
	since_previous_version: 'Since previous version',
	data_health: {
		rule_enabled: 'Enable Healthcheck',
		inherit: 'Inherits rules from the category',

		global: 'Main date field',
		data_field: 'Main date field',

		consistency: 'Consistency',
		coherence: 'Coherence',
		recency: 'Recency',
		cs: 'Consistency',
		ch: 'Coherence',
		rc: 'Recency',

		status: {
			error: 'Issues found',
			missing_config: 'Missing config',
			missing: 'Missing config',
			ok: 'No issue found',
			empty: 'No data',
			success: 'No issue found',
			warning: 'No data',
		},
		error: {
			ch_date_bounds: 'ch_date_bounds error',
		},
		rules: {
			cs_date_gaps: {
				title: 'No gap in records should be detected across time',
				short_title: 'No gap',
				description:
					'If a period has no record, it may indicate some historical data are missing. This rule detects this case. If grouping parameters are specified, the check will be performed for each grouping.',
				period: 'Period',
				unit: 'Frequency',
				fields: 'Fields',
			},
			cs_records_by_date: {
				title: 'Number of records per period should be relatively similar across time',
				description:
					'Strong variations of the number of records per period may indicate that data are missing. This rule aims to detect the case based on a standard deviation variation threshold.',
				limit: 'Maximum number of times the records count standard deviation is allowed before an alert is triggered',
				unit: 'Frequency',
			},
			ch_previous_version: {
				title: 'Compare quantities with the previous version',
				short_title: 'Previous version',
				description:
					'Compare overlapping values between the previous and the current version to detect unexpected value changes. Any change bigger than the threshold will fail the health check.',
				fields: 'Fields',
				quantity_field: 'Quantity field',
				quantity_multiplier_field: 'Quantity multiplier field',
				threshold: 'Threshold (%)',
			},
			ch_previous_date: {
				title: 'Compare the newest values with existing dataset values',
				short_title: 'Previous date',
				description:
					'Compare the most recent values with older values in the dataset to detect unusual variations. Any change bigger than the threshold will fail the health check.',
				fields: 'Fields',
				quantity_field: 'Quantity field',
				quantity_multiplier_field: 'Quantity multiplier field',
				period: 'Period to consider',
				number_of_periods: 'Number of period to consider in the past',
				threshold: 'Threshold (%)',
			},
			cs_suspect_ref: {
				title: 'No unusual reference should be found for reference fields',
				description:
					'Important imbalances between reference presence may indicate data have been loaded by mistake. This rule analyzes this case based on a presence ratio',
				limit: 'Percentage under which the alert is triggered',
				fields: 'References to monitor',
			},
			cs_missing_mandatory: {
				title: 'No mandatory field should be empty',
				description: 'Mandatory fields should never present any missing value. The rule detects this case.',
			},
			ch_date_bounds: {
				title: 'Dates should be within expected bounds',
				description:
					'Dates far in the past or in the future are potential data issues. The rule detects this case.',
				min: 'Minimum correct date',
				max: 'Number of days/months/etc in the future for which data remains coherent',
				unit: 'Frequency',
			},
			ch_numeric_outliers: {
				title: 'Numerical values should remain in expected bounds',
				description:
					'Numbers that are unusually higher than others are probable mistakes. The rule detects this case.',
				limit: 'Maximum number of times the standard deviation is allowed before an alert is triggered',
			},
			ch_nonexisting_ref: {
				title: 'Deleted references should not be detected',
				fields: 'References to monitor',
				description:
					'Deleted references may leave a dataset with incoherent data. The rule will detect presence of references that are not existing anymore.',
			},
			rc_outdated: {
				title: 'Data should be recent',
				description:
					'Even if a dataset is updated on a regular basis. Its content may be unchanged. The rule detects that the maximum represented date is outdated.',
				unit: 'Frequency',
				limit: 'Number of days/months/etc in the past for which data is still considered recent and valid',
			},
		},
		alert_under_edit: 'This update will take effect in the next workflow execution',
	},
	steps: {
		category: {
			title: 'Category',
			description: 'Select category',
		},
		structure: {
			title: 'Structure',
			description: 'Update structure',
		},
		settings: {
			title: 'Settings',
			description: 'Add settings',
		},
		naming: {
			title: 'Naming',
			description: 'Get dataset',
		},
	},
	grades: {
		None: 'None: Dataset created',
		Iron: 'Iron: Dataset metadata updated',
		Bronze: 'Bronze: Non empty payload in workflow attached to dataset',
		Silver: `Silver: Dataset has been filled, but contain unknown values. It can't be used in other resources`,
		Gold: 'Gold: Dataset filled and has no unknown values',
		Platinum: 'Platinum: Dataset has content and passed healthCheck',
	},
	compliance_level: {
		level_0: {
			label: '0: Cannot share the data',
			title: 'Cannot share the data, can use it anywhere',
		},
		level_1: {
			label: '1: Can share the data',
			title: 'Can share the data and use it anywhere',
		},
		level_2: {
			label: '2: Can share the data but the source has to be hidden',
			title: 'Can share the data and use it anywhere but the source has to be hidden',
		},
		level_3: {
			label: '3: Can share a derivative of the data but not the data itself',
			title: 'Can share a derivative of the data but not the data itself (example share a dashboard)',
		},
		level_4: {
			label: '4: Can only use it in a report or in a context of a presentation',
			title: 'Can only use it in a report or in a context of a presentation',
		},
		level_5: {
			label: "5: Can't use the data anywhere",
			title: "Can't use the data anywhere (it can be used as a tool for our forecast)",
		},
		level_6: {
			label: '6: The data can only be used in a internal usage of the platform',
			title: 'The data can only be used in a internal usage of the platform (by default)',
		},
		level_7: {
			label: '7: The data can only be use as a derivative and the source Dnext is quoted',
			title: 'The data can only be use as a derivative and the source Dnext is quoted',
		},
		level_8: {
			label: '8: The data can be download and share with anyone',
			title: 'The data can be download and share with anyone',
		},
	},
	no_settings_found: 'No settings has been found',
	data_exploration: {
		NO_FILTER_FOUND: 'This data has no filters to use yet.',
		NO_FILTER_FOUND_DESCRIPTION: 'Around 3 M rows to display',
		actions: {
			add_filter: 'Add Filter',
			reset_changes: 'Reset changes',
			publish: 'Save & Publish new version',
		},
		labels: {
			apply_filter: 'Apply filter',
			get: 'Get filters',
			add: 'Create new filter',
			edit: 'Edit filter',
			delete: 'Delete filter',
			settings: 'Settings',
			list: 'Filters list',
			preview: 'Preview dataset',
			is_default: 'Set as default',
			update_values: 'Update values',
			load_history: 'Load History',
			date: 'Date',
			editor: 'Editor',
			rows: 'Rows',
		},
		info: {
			update_dataset_row_started: 'Update dataset values has started. Saving your changes might take some time.',
		},
		warnings: {
			apply_filter:
				"Your data will not be refreshed until you apply the filters. Please define your filters on the columns first, then click the 'Apply Filters' button to update the data. If you change any filter settings, remember to click the button again to see the updated results.",
			transaction_might_take_time:
				'Warning: You have more than 15 transactions. Saving your changes might take some time',
			transaction_limit:
				'You have exceeded the maximum limit of 100 transactions. Please reduce the number of changes before saving',
		},
		success: {
			SUCCESS_ADD_FILTER: 'New filter added successfully',
			SUCCESS_EDIT_FILTER: 'Filter updated successfully',
			SUCCESS_DELETE_FILTERS: 'Filter deleted successfully',
			SUCCESS_EDIT_DATASET: 'Dataset values updated successfully',
		},
		errors: {
			ERROR_GET_ROWS_DATASET: 'An error occured while getting dataset records',
			ERROR_GET_FILTERS: 'An error occured while getting the filters list',
			ERROR_ADD_FILTER: 'An error occured while adding a new filter',
			ERROR_EDIT_FILTER: 'An error occured while updating this filter',
			ERROR_DELETE_FILTER: 'An error occured while deleting this filter',
			ERROR_EDIT_DATASET: 'An error occured while updating dataset values',
		},
		validations: {
			filter_name: 'Filter name is required',
		},
	},
};
