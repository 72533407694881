// @ts-nocheck
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Ant design & icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRandom } from '@fortawesome/pro-light-svg-icons';
import { faPlus, faTrash, faPause, faStop, faCircle, faPlay, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip, Radio, Dropdown, Menu, PageHeader, Popconfirm, Tabs, Checkbox, Popover, Empty } from 'antd';
// context
import userContext from 'src/libs/contextLib';
// components
import List from 'ui/components/List/List';
import ReferenceSelect from 'ui/components/Form/ReferenceSelect';
import OneColumnPage from 'ui/layout/OneColumnPage/OneColumnPage';
// services
import DataService from 'src/utils/DataService';
import LabelService from 'ui/modules/Core/services/label.service';
// helpers & utils
import _ from 'lodash';
import { contextOrAPIGetLabels } from 'src/ui/modules/Core/utils/label.helper';
import { pushNotification } from 'src/utils/PushNotification';
import { getOneLabel } from 'src/ui/modules/Core/utils/label.helper';
import { getPayloadApplyLabel } from 'ui/modules/Core/utils/shared.helper';
// configs
import config from 'src/config';

export class RecordList extends PureComponent {
	static contextType = userContext;

	static propTypes = {
		recordType: PropTypes.string.isRequired,
		configList: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			showDeleteButton: false,
			showToggleActiveButton: false,
			showMoveParentSelect: false,
			confirmProps: {
				confirmFunction: null,
				confirmtext: '',
			},
			active: false,
			approve: false,
			loading: false,
			state_wf: 'runnable',
			tags: {},
			tagsByDatasetId: {},
			selectedRecords: [],
			selectedParent: null,
			currConfig: this.props.configList,
			cTab: null,
			labels: [],
		};
	}

	componentDidMount() {
		if (this.props.configList.canChangeTags) {
			this.getAllLabels();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Call getSelectedTags method when selectedRecords state changes
		if (prevState.selectedRecords !== this.state.selectedRecords) {
			this.getSelectedTags();
		}
	}

	/* call labels services to get */
	getAllLabels = async () => {
		const fetchedLabels = await contextOrAPIGetLabels(
			this.props.addToast,
			this.props.t,
			this.props.getLabelsListValues,
			this.props.setLabelsListValues,
			this.props.isLabelsListLoaded,
			this.props.recordType
		);
		this.setState({ labels: fetchedLabels || [] });
	};

	// Here we can capture list functions
	async onListReady(params) {
		this.deleteSelectedRecords = params.deleteSelectedRecords;
		this.toggleActiveRecords = params.toggleActiveRecords;
		this.toggleApprovalRecords = params.toggleApprovalRecords;
		this.toggleStateWorkflows = await params.toggleStateWorkflows;
		this.moveParentSelectedRecords = params.moveParentSelectedRecords;
		this.ApplyTagsEntity = await params.ApplyTagsEntity;
		this.getSelectedRecords = params.getSelectedRecords;
		this.showBulkExecuteModal = params.showBulkExecuteModal;
	}

	onSelectionChanged(params) {
		this.setState({
			showDeleteButton:
				!config?.restrictedPermission?.delete.includes(this.props.recordType) &&
				this.getSelectedRecords().length > 0 &&
				(this.props.recordType != 'system_entity' ||
					!this.getSelectedRecords().some((record) => record.type === 'business_category')) &&
				this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_delete,
			showBulkExecute: this.getSelectedRecords().length > 0 && this.props.configList.canBulkExecute,
			showToggleActiveButton:
				this.props.permissionRecord === 'reference' &&
				this.getSelectedRecords().length > 0 &&
				this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_update,
			showUpdateState: this.getSelectedRecords().length > 0 && this.props.configList.canChangeState,
			showTagsUpdate: this.getSelectedRecords().length > 0 && this.props.configList.canChangeTags,
			showMoveParentSelect:
				this.props.permissionRecord === 'reference' &&
				this.props.configList?.parentField &&
				this.getSelectedRecords().length > 0 &&
				this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_update,
			selectedRecords: this.getSelectedRecords(),
		});
	}

	openFormNewRecord() {
		this.props.history.push({
			pathname: `${this.props.configList.path}/${config.interface.newRecordUrlValue}`,
			state: this.props.history?.location?.state || null,
		});
	}

	openDepthReferenceView() {
		this.props.history.push(`${this.props.configList.path}/tree`);
	}

	handleStateActiveChange = (e) => {
		this.setState({ active: e.target.value });
		this.toggleActiveRecords(e.target.value);
	};

	handleApprovalChange = (e) => {
		this.setState({ approve: e });
		this.toggleApprovalRecords(e);
	};

	handleStateChange = (e) => {
		this.setState({ selectedParent: e });
	};

	handleMoveParent() {
		this.moveParentSelectedRecords(this.state.selectedParent);
	}

	async handleChangeState(value) {
		this.setState({ state_wf: value.key, loading: true });
		const b = await this.toggleStateWorkflows(value.key);
		if (b === 'done') this.setState({ loading: false });
	}

	switchListTab(value) {
		let c = value;
		if (c === 'List') c = this.props.configList;
		else {
			c = config.records[value];
			c.oldUrl = !c.oldUrl ? c.url : c.oldUrl;
			c.url = `${c.oldUrl}/${this.props.configList.sharedName}`;
			c.oldPath = !c.oldPath ? c.path : c.oldPath;

			if (!c.samePath) {
				c.path = `${c.oldPath}/${this.props.configList.sharedName}`;
			} else {
				c.path = this.props.configList.path;
			}
			c.canDelete = false;
			c.showCheckBox = !!c.canApprove;
		}

		this.setState({
			currConfig: c,
			cTab: value,
		});
	}

	renderList() {
		const { currConfig } = this.state;
		const { getService } = currConfig;
		const getDataService =
			getService?.()?.instance() ||
			new DataService({
				url: currConfig.url,
				token: true,
				urlParams: currConfig.noParams
					? ''
					: `?limit=5000${currConfig.urlParams != null ? `&${currConfig.urlParams}` : ''}`,
			});

		return (
			<List
				history={this.props.history || null}
				t={this.props.t || null}
				isReferenceListLoaded={this.props.isReferenceListLoaded || null}
				setReferenceListValues={this.props.setReferenceListValues || null}
				getReferenceListValues={this.props.getReferenceListValues || null}
				subscribeToType={this.props.subscribeToType || null}
				unsubscribeFromType={this.props.unsubscribeFromType || null}
				pushModal={this.props.pushModal || null}
				addToast={this.props.addToast || null}
				configList={currConfig}
				showCheckBox={currConfig.canDelete || currConfig.showCheckBox}
				canFilter={currConfig.canFilter}
				showLock={!!currConfig.canLock}
				columns={(currConfig.columns || []).filter((el) => {
					return !el.mustHasRight || this.myPermissions?.[this.props.recordType]?.can_manage;
				})}
				dataService={getDataService}
				bulkDeleteService={getService?.()?.bulkDelete || null}
				duplicateService={getService?.()?.duplicate || null}
				urlParameters={currConfig.urlParams != null ? `&${currConfig.urlParams}` : ''}
				useSearchUrl={currConfig.useSearchUrl}
				paramSearch={currConfig.paramSearch}
				defaultSortModel={currConfig.sortModel}
				path={currConfig.path}
				listButtons={Array.isArray(currConfig.listButtons) ? currConfig.listButtons : []}
				moreActions={Array.isArray(currConfig.moreActions) ? currConfig.moreActions : []}
				actions={{
					visualize:
						!config?.restrictedPermission?.read.includes(this.props.recordType) &&
						this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_read,
					edit: this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_update,
					delete:
						(!config?.restrictedPermission?.delete.includes(this.props.recordType) &&
							this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_delete) ||
						false,
					execute: !!currConfig.canExecuteWorkflow && this.myPermissions?.workflow.can_update,
					execute_data_prep: !!currConfig.canExecuteDataPrep,
					canlock: !!currConfig.canLock,
					canbecloned: !!currConfig.canbeCloned,
					canbeduplicate: !!currConfig.canbeDuplicate,
					canbeSettings: !!currConfig.canbeSettings,
					canRefreshAndView: !!currConfig.canRefreshAndView,
					canRefreshAndDownload: !!currConfig.canRefreshAndDownload,
					canDownload:
						!!currConfig.canDownload &&
						this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_download,
					canSeeStats: !!currConfig.canSeeStats,
				}}
				onReceiveNotification={this.props.onReceiveNotification}
				onListReady={this.onListReady.bind(this)}
				onSelectionChanged={this.onSelectionChanged.bind(this)}
				parentField={currConfig.parentField}
				user={this.props.user}
				useTab={currConfig.useTab}
				navigateByCode={currConfig.navigateByCode || false}
				navigateByField={currConfig.navigateByField || false}
				recordType={this.props.recordType}
				referenceListConsumerProps={this.props.referenceListConsumerProps}
				permissionRecord={this.props.permissionRecord || this.props.recordType}
				isShowHierarchy={!!currConfig.isShowHierarchy}
			/>
		);
	}

	getSelectedTags() {
		const labelsArray = (this.getSelectedRecords() || []).map((el) =>
			this.props.recordType === 'workflow' ? el.dataset.labels : el.labels
		);

		// Flatten the array and remove null values
		const flattenedLabels = _.compact(_.flatten(labelsArray));

		// Group tags by their id
		const groupedLabels = _.groupBy(flattenedLabels, 'id');

		// map result
		const tags = {};

		_.map(groupedLabels, (item, key) => {
			// check if key exist in labels listing
			if (this.state.labels.find((el) => el.id === key)) {
				tags[key] = {
					checked: item.length === labelsArray.length,
					indeterminate: item.length !== labelsArray.length,
				};
			}
		});
		this.setState({ tags });
	}

	onChangeLabel = (e, labelId) => {
		const { checked } = e.target;
		this.setState({
			tags: {
				...this.state.tags,
				[labelId]: {
					indeterminate: false,
					checked,
				},
			},
		});
	};

	async handleChangeTags() {
		this.setState({ loading: true });
		const selectedRows = this.getSelectedRecords() || [];
		const { resourceType, resource_ids, label_ids } = getPayloadApplyLabel(
			this.props.recordType,
			selectedRows,
			this.state.tags
		);

		this.ApplyTagsEntity({ resourceType, resource_ids, label_ids }).finally(() => {
			this.setState({ loading: false });
		});
	}

	render() {
		const userRights = this.props.restrictAccess;
		const { context } = this;
		this.org = context.user.org || null;
		this.myPermissions = context.myPermissions || null;

		const controls = this.props.controls ? [...this.props.controls] : [];

		if (this.state.showToggleActiveButton) {
			const toggleActiveButton = (
				<span key="activeBtn">
					Set to
					<Radio.Group value={this.state.active} className="ml-1" onChange={this.handleStateActiveChange}>
						<Radio.Button value="true" key="true">
							inactive
						</Radio.Button>
						<Radio.Button value="false" key="false">
							active
						</Radio.Button>
					</Radio.Group>
				</span>
			);
			controls.push(toggleActiveButton);
		}

		if (this.state.currConfig.canApprove && this.props.user.can_approve) {
			const toggleApprovalButton = (
				<span>
					<Popconfirm
						title="Are you sure to approve?"
						onConfirm={() => this.handleApprovalChange('true')}
						okText="Yes"
						cancelText="No"
						className="ml-auto"
						loading={this.state.loading}
					>
						<Button value="true">Approve</Button>
					</Popconfirm>
					<Popconfirm
						title="Are you sure to disapproved?"
						onConfirm={() => this.handleApprovalChange('false')}
						okText="Yes"
						cancelText="No"
						className="ml-auto"
						loading={this.state.loading}
					>
						<Button value="false">disapprove</Button>
					</Popconfirm>
				</span>
			);
			controls.push(toggleApprovalButton);
		}

		if (this.state.showTagsUpdate) {
			const showTagsUpdate = (
				<span key="tags-resource">
					<Popover
						content={
							this.state.labels.length ? (
								<>
									{_.map(this.state.labels, (label) => {
										return (
											<div key={label.id}>
												<Checkbox
													className="mb-1"
													value={label.id}
													key={label.id}
													checked={this.state.tags?.[label.id]?.checked || false}
													indeterminate={this.state.tags?.[label.id]?.indeterminate || false}
													onChange={(e) => this.onChangeLabel(e, label.id)}
													disabled={_.toLower(label.name) === 'shared'} // TODO: clean this condition
												>
													{getOneLabel(label)}
												</Checkbox>
											</div>
										);
									})}
									<br />
									<Button
										type="primary"
										className="mt-2"
										size="small"
										loading={this.state.loading}
										onClick={() => this.handleChangeTags()}
									>
										Apply
									</Button>
								</>
							) : (
								<Empty className="w-100" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No labels" />
							)
						}
						trigger="click"
					>
						<Button>{this.props.t('model.label.name_plural')}</Button>
					</Popover>
				</span>
			);
			controls.push(showTagsUpdate);
		}

		if (this.state.showUpdateState) {
			const icons = {
				runnable: <FontAwesomeIcon icon={faCircle} className=" mr-0 recording" />,
				standby: <FontAwesomeIcon icon={faPause} className="mr-0" />,
				stopped: <FontAwesomeIcon icon={faStop} className="mr-0" />,
			};

			const menu = (
				<Menu onClick={(key) => this.handleChangeState(key)}>
					{Object.keys(icons).map((o) => (
						<Menu.Item key={o} icon={icons[o]} />
					))}
				</Menu>
			);

			const showUpdateStateBtn = (
				<span key="updateWorkflowState">
					{this.props.t('actions.update_workflow_state')}:{' '}
					<Dropdown overlay={menu} trigger={['click']}>
						<Button
							style={{
								color: '#222222',
								border: 'none',
								marginTop: '0px',
								lineHeight: '0px',
								verticalAlign: '0px',
							}}
							onClick={(e) => e.preventDefault()}
							loading={this.state.loading}
							icon={icons[this.state.state_wf] || '...'}
						/>
					</Dropdown>
				</span>
			);
			if (this.myPermissions?.workflow.can_update) controls.push(showUpdateStateBtn);
		}

		if (this.state.showMoveParentSelect && !userRights) {
			const SelectParent = (
				<div className="overWriteantdtable">
					<ReferenceSelect
						recordtype={this.props.recordType.toLowerCase()}
						onChange={this.handleStateChange.bind(this)}
						addnone
						className="ml-3"
						style={{ width: '100%', minWidth: '400px' }}
						disabledItems={this.state.selectedRecords.map((el) => el.id)} // To avoid selection of itself as a parent
						isReferenceListLoaded={this.props.isReferenceListLoaded}
						setReferenceListValues={this.props.setReferenceListValues}
						getReferenceListValues={this.props.getReferenceListValues}
					/>
					<Button type="primary" className="ml-1" onClick={() => this.handleMoveParent()} key="moveparent">
						<FontAwesomeIcon icon={faRandom} className="mr-1" />
						Change parent
					</Button>
				</div>
			);
			controls.push(SelectParent);
		}

		if (this.props.configList.actions) {
			this.props.configList.actions.forEach((action) => {
				if (React.isValidElement(action)) {
					controls.push(
						React.cloneElement(action, {
							onClick: action.props?.onClick?.bind(this),
						})
					);
				} else if (typeof action === 'function') {
					controls.push(action(this.props.history, this.props.t));
				} else {
					console.warn('Unsupported action type', action);
				}
			});
		}
		// reference depth
		if (this.props.configList.referenceData && this.props.configList.parentField) {
			const depthButton = (
				<Button type="second" onClick={() => this.openDepthReferenceView()} key="depth">
					<FontAwesomeIcon icon={faSitemap} className="mr-1" />
					{this.props.t('model.reference_data.actions.depth')}
				</Button>
			);
			controls.push(depthButton);
		}

		if (
			!config?.restrictedPermission?.create.includes(this.props.recordType) &&
			this.myPermissions?.[this.props.permissionRecord || this.props.recordType]?.can_create
		) {
			const newButton = (
				<Button type="primary" onClick={() => this.openFormNewRecord()} key="create_new">
					<FontAwesomeIcon icon={faPlus} className="mr-1" />
					{this.props.t(`model.${this.props.recordType.toLowerCase()}.actions.create_new`)}
				</Button>
			);
			controls.push(newButton);
		}

		if (this.state.showBulkExecute && !userRights) {
			controls.push(
				<Tooltip title="Execute selected records" placement="bottomRight" mouseLeaveDelay="0">
					<Button
						type="primary"
						onClick={() => {
							this.showBulkExecuteModal();
						}}
					>
						<FontAwesomeIcon icon={faPlay} className="mr-2" />
						Execute
					</Button>
				</Tooltip>
			);
		}

		if (this.state.showDeleteButton && !userRights) {
			const deleteButton = (
				<Tooltip
					key="tooltipdelete"
					title="Delete selected records"
					placement="bottomRight"
					mouseLeaveDelay="0"
				>
					<Button
						type="danger"
						onClick={() => {
							this.deleteSelectedRecords();
						}}
					>
						<FontAwesomeIcon icon={faTrash} className="mr-2" />
						Delete
					</Button>
				</Tooltip>
			);
			controls.push(deleteButton);
		}

		let parentPage = null;

		if (
			this.props.history.location.state &&
			this.props.history.location.state.from &&
			this.props.history.location.state.goFrom
		) {
			parentPage = {
				name: this.props.history.location.state.from,
				url: this.props.history.location.state.goFrom,
			};
		}

		const list = this.renderList();

		if (this.props.onlylist) {
			return (
				<>
					<PageHeader
						className="pl-0 pr-0 mb-1 pt-0"
						ghost
						title={this.props.t(
							this.props.configList?.pageTitle?.list ||
								`model.${this.props.recordType.toLowerCase()}.name_plural`
						)}
						subtitle={this.props.t(
							this.props.configList.subtitle || `model.${this.props.recordType.toLowerCase()}.description`
						)}
						extra={_.isArray(controls) ? controls : _.values(controls)}
					/>
					{list}
				</>
			);
		}

		return (
			<OneColumnPage
				title={this.props.t(
					this.props.configList?.pageTitle?.list || `model.${this.props.recordType.toLowerCase()}.name_plural`
				)}
				{...this.props}
				help={
					this.props.configList?.pageTitle?.help ? (
						<Tooltip
							title={this.props.t(
								this.props.configList?.pageTitle?.help.replace('{ORGANIZATION_ID}', this.org)
							)}
						>
							<FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
						</Tooltip>
					) : null
				}
				subtitle={this.props.t(
					this.props.configList.subtitle || `model.${this.props.recordType.toLowerCase()}.description`
				)}
				controls={controls}
				parentPage={parentPage || this.props.configList.parentPage}
				parents={this.props.parents}
				history={this.props.history || null}
			>
				{list}
			</OneColumnPage>
		);
	}
}

export default withTranslation()(RecordList);
