import fundamentals from './Fundamentals';
import pipeline from './Pipeline';
import storage from './Storage';
import data_management from './DataManagement';
import core from './Core';

export default {
	fundamentals,
	pipeline,
	storage,
	data_management,
	core,
};
