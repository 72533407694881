import React, { FC } from 'react';
import { ColDef } from 'ag-grid-community';
// Shared utils
import { map, split, capitalize } from 'lodash';
// Ant Design
import { Space, Tag } from 'antd';
import config from 'src/config';

interface ExtendedColDef extends ColDef {
	tag_separator?: string;
}

interface TagRendererProps {
	value: string | number;
	null_value?: any;
	color?: string;
	colDef?: ExtendedColDef;
}
const TagRenderer: FC<TagRendererProps> = ({ value, colDef, null_value, color }) => {
	const mapping: Record<string | number, string> = {
		...config.labels.system,
		...config.labels.dataset,
		...config.labels.trade_flow,
		...config.labels.data_prep,
		...config.labels.forecast,
	};

	let displayValue = value || null_value;

	if (!displayValue) return null;

	displayValue = split(displayValue, colDef?.tag_separator || ',');

	return map(displayValue, (tag, index) => {
		if (tag === '_') return tag;
		return (
			<Space wrap key={index}>
				<Tag style={{ textAlign: 'center' }} color={mapping[tag] ? mapping[tag] : color}>
					{capitalize(tag.replace('_', ' '))}
				</Tag>
			</Space>
		);
	});
};

export default TagRenderer;
