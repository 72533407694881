import api_key from './api_key';
import category from './category';
import currency from './currency';
import custom_code from './custom_code';
import dataset from './dataset';
import email from './email';
import exchange from './exchange';
import location from './location';
import product from './product';
import product_group from './product_group';
import group from './group';
import location_group from './location_group';
import source from './source';
import approval from './approval';
import production from './production';
import trade_flow_mode from './trade_flow_mode';
import trade_flow from './trade_flow';
import tradeflow from './tradeflow';
import tradeflow_comparison_view from './tradeflow_comparison_view';
import transport_type from './transport_type';
import user from './user';
import unit from './unit';
import product_category from './product_category';
import workflow from './workflow';
import price_type from './price_type';
import period_aggregation_type from './period_aggregation_type';
import vegetation_index_variable from './vegetation_index_variable';
import cost_production_item from './cost_production_item';
import snd_item from './snd_item';
import vessel_status from './vessel_status';
import company from './company';
import company_group from './company_group';
import transport_category from './transport_category';
import delivery_term from './delivery_term';
import exchange_month_reference from './exchange_month_reference';
import data_type from './data_type';
import quote_type from './quote_type';
import dashboard from './dashboard';
import forecast from './forecast';
import email_ruleset from './email_ruleset';
import list from './list';
import support from './support';
import report from './report';
import reference_data from './reference_data';

import label from './label';
import system_entity from './system_entity';
import voyage from './voyage';
import estimate from './estimate';
import pipeline from './pipeline';

import home from './home';
import files from './files';
import advanced_search from './advanced_search';
import compliance from './compliance';
import sharing from './sharing';
import script_library from './script_library';

export default {
	home,
	files,
	api_key,
	category,
	email,
	product,
	product_group,
	dataset,
	location,
	custom_code,
	user,
	group,
	location_group,
	currency,
	unit,
	exchange,
	source,
	trade_flow_mode,
	trade_flow,
	pipeline,
	tradeflow_comparison_view,
	transport_type,
	product_category,
	workflow,
	price_type,
	period_aggregation_type,
	cost_production_item,
	vegetation_index_variable,
	snd_item,
	approval,
	production,
	company,
	company_group,
	vessel_status,
	transport_category,
	delivery_term,
	exchange_month_reference,
	data_type,
	quote_type,
	tradeflow,
	email_ruleset,
	dashboard,
	forecast,
	list,
	report,
	reference_data,

	voyage,
	estimate,
	support,
	advanced_search,
	label,
	system_entity,
	compliance,
	sharing,
	script_library,
};
