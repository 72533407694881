export default {
	name: 'Forecast',
	name_plural: 'Forecasts',
	no_x: 'No forecast',
	actions: {
		create_new: 'Create a new forecast',
	},
	error: {
		update_error: 'Error updating forecast:',
		retrieve_data: 'A problem occured while retrieving forecast data.',
		form_values: 'Issue with form values, make sure you fill all required fields',
	},
	success: {
		update_success: 'Forecast updated successfully.',
	},
	messages: {
		update_error: 'A problem occured while updating this record. Please try again later.',
		update_success: 'This record has been successfully updated.',
	},
	infos: {
		required: 'This field is required',
		get_forecast: 'Error getting Forecast',
	},
};
