//constants
import { ScriptLibraryColumns } from 'src/ui/modules/Core/constants/script-library.constant';
//services
import ScriptLibraryService from 'ui/modules/Core/services/script-library.service';

export default {
	referenceData: false,
	iconName: 'ScriptLibrary',
	path: '/script-library',
	pageTitle: {
		list: 'Script library',
		action: {
			create: 'New script',
			edit: 'Edit script',
		},
	},
	columns: ScriptLibraryColumns,
	canChangeTags: true,
	getService: () => ({
		instance: ScriptLibraryService.getInstance,
		bulkDelete: ScriptLibraryService.bulkDelete,
	}),
};
