import DataService from 'src/utils/DataService';
import { uniqBy, compact } from 'lodash';
import { fundamentals, getApiPrefix, server } from 'src/utils/api-prefix.helper';
import config from 'config';
import { IPreviewPayload } from 'ui/modules/Core/types/interfaces/dataset.interface';
import { IProduction } from '../types/interfaces/production.interface';

const productionUrl = `${server}${getApiPrefix()}/${fundamentals}/production`;
export const getProductionService = () => {
	return new DataService({
		url: productionUrl,
		urlParams: '',
		token: true,
	});
};

export const getOneProduction = (code: string) => {
	return new DataService({
		url: productionUrl,
		urlParams: '',
		token: true,
	}).getClean(code);
};

export const updateProduction = async (id: string, values: any) => {
	try {
		const result = await new DataService({
			url: productionUrl,
			urlParams: '',
		}).update(id, values);

		return result.data;
	} catch (err) {
		return err;
	}
};

export const createProduction = async (values: any) => {
	try {
		const result = await new DataService({
			url: `${productionUrl}/create`,
			urlParams: '',
		}).create(values);

		return result.data;
	} catch (err) {
		return err;
	}
};

// TODO: To be remove after removing old sharing
export const getAvailableProductions = async (id: string, selectFormat = false) => {
	const tfs = await Promise.all([
		getProductionService().getAll(),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[production]',
		}).getAll(),
	]);
	const data: IProduction[] = [];
	tfs.map((x: any, i: number) => {
		if (i === 1) data.push(x.data.map((c: any) => c.trade_flow));
		else data.push(x.data.result.filter((c: IProduction) => c.id !== id));
	});
	if (selectFormat) {
		return uniqBy(compact(data?.flat()), 'code').map((el: IProduction) => ({
			id: el?.id,
			key: el?.id,
			code: el?.code,
			title: el?.name || null,
			description: el?.name || null,
			parent: el?.parent ? el.parent?.name : null,
		}));
	}

	return uniqBy(data.flat(), 'code');
};

export const getProductionPreview = async (code: string, payload: IPreviewPayload) => {
	const result = await new DataService({
		url: `${productionUrl}/${code}/preview`,
		urlParams: '',
	}).create(payload);
	return result?.data || [];
};
