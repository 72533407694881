import { DataService } from '@utils';
import { Moment } from 'moment';
import configFreight from 'ui/modules/Freight/config/freight.config';
import { getLastWorkingDay } from 'ui/modules/Freight/utils/spot-date.helper';
import { IEstimate } from 'ui/modules/Freight/types/interfaces/estimate.interface';
import { formatDate } from 'src/helpers/date.helpers';

const { freight_estimates, estimate_infos, freight_infos } = configFreight;

export function getAllEstimateService() {
	return new DataService({ url: freight_estimates.url, token: true });
}

export async function createEstimateService(estimate: IEstimate) {
	return new DataService({ url: freight_estimates.url, params: '', token: true }).post(estimate);
}

export async function updateEstimateService(estimateId: string, estimate: IEstimate) {
	return new DataService({ url: freight_estimates.url, params: '', token: true }).update(estimateId, estimate);
}

export async function executeEstimateService(estimate: IEstimate) {
	return new DataService({ url: `${freight_estimates.url}/calc`, params: '', token: true }).post(estimate);
}

export async function getEstimateDetailService(estimateId: string) {
	return new DataService({
		url: `${freight_estimates.url}/${estimateId}`,
		params: '',
		token: true,
	}).getAll();
}

export async function getFreightInfoService(spotDate: Moment | Date | undefined | null = null) {
	const defaultSpotDate = getLastWorkingDay();
	const date = formatDate(spotDate || defaultSpotDate);
	const url = `${freight_infos.url}?spot_date=${date}`;
	return new DataService({ url }).getAll();
}

export async function getEstimateInfoService(estimateId: string = '') {
	const url = `${estimate_infos.url}/${estimateId}`;
	return new DataService({ url }).getAll();
}

export async function deleteEstimateService(estimateId: string) {
	return new DataService({ url: freight_estimates.url, params: '', token: true }).delete(estimateId);
}

export async function bulkDeleteEstimateService(payload: { ids: string[] }) {
	return await new DataService({
		url: `${freight_estimates.url}/delete`,
		urlParams: '',
	}).post(payload);
}
