import React from 'react';
// Services
import TradeflowService from 'ui/modules/Fundamentals/Tradeflows/services/tradeflow.service';
// Components
import ForecastButton from 'ui/modules/Fundamentals/components/ForecastButton/ForecastButton';
// Helpers
import { server, getApiPrefix, fundamentals } from 'src/utils/api-prefix.helper';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: 'TradeMatrix',
	url: `${serverUrl}${fundamentals}/tradeflows`,
	oldUrl: `${server}${fundamentals}/tradeflow`,
	getService: () => ({
		instance: TradeflowService.getInstance,
		bulkDelete: TradeflowService.bulkDelete,
		duplicate: TradeflowService.duplicate,
	}),
	recordid: 'trade_flow_id',
	urlParams: 'relation=[trade_flow_snapshot,trade_flow_mode]',
	path: '/fundamentals/tradematrix',
	parentPage: {
		name: 'Fundamentals',
	},
	pageTitle: {
		list: 'TradeMatrix',
		action: {
			create: 'New TradeMatrix',
			edit: 'Edit TradeMatrix',
		},
	},
	actions: [<ForecastButton />],
	formatDataFromList: (data: any) =>
		data.map((d: any) => {
			// get last trade flow snapshot created at
			let lastSnapShotCreatedAt = null;
			if (d.trade_flow_snapshot) {
				const snapshotList = [...d.trade_flow_snapshot];
				lastSnapShotCreatedAt = snapshotList.sort((a, b) =>
					a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0
				)[0];
			}

			return {
				...d,
				lastSnapShotCreatedAt: d.last_refreshed_date
					? d.last_refreshed_date
					: lastSnapShotCreatedAt
						? lastSnapShotCreatedAt.created_at
						: null,
			};
		}),
	listButtons: ['canRefreshAndView', 'canbeDuplicate', 'canbeSettings', 'canDownload', 'canRefreshAndDownload'], // ,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: false,
	canbeCloned: false,
	canbeDuplicate: true,
	canbeSettings: true,
	canAccessForecasts: true,
	canRefreshAndView: true,
	canDownload: true,
	canRefreshAndDownload: true,
	hasShared: true,
	canChangeTags: true,
	navigateByCode: true,
};
