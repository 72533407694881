// dependencies
import Axios from 'axios';
import { saveAs } from 'file-saver';
// helpers
import DataService from 'utils/DataService';
import { getTaskStatus } from 'src/utils/getResource';
import { sleep } from 'utils/Validator';
import { dataManagement, dataPrep, getApiPrefix, server } from 'src/utils/api-prefix.helper';
// configs
import config from 'src/config';
//constants
const serverUrl = `${server}${getApiPrefix()}/`;
const dataPrepUrl = `${serverUrl}${dataPrep}`;
const pipelineUrl = `${serverUrl}${dataManagement}/pipeline`;
export const nonRefTypes = ['text', 'numeric', 'numeric-dot', 'numeric-comma', 'static', 'null', 'date', 'datetime'];

export const getPipelineService = () => {
	const split = config.api.split || 3000;
	return new DataService({
		url: `${dataPrepUrl}/?limit=${split}&offset=${0}`,
		urlParams: '',
	});
};

export const getPipeline = async (id, relation = false) =>
	await new DataService({
		url: pipelineUrl,
		urlParams: relation ? `?relation=${relation}` : '',
	}).get(id);

export const getPipelineByCode = async (code) =>
	await new DataService({
		url: `${dataPrepUrl}/${code}`,
		urlParams: '',
	}).getAll();

export const previewQuery = (code, sources, fields, query, offset = 0, limit = 10) =>
	new Promise(async (resolve) => {
		try {
			const data = await new DataService({
				url: `${dataPrepUrl}/${code}/preview-query`,
				urlParams: '',
			}).create({
				sources,
				fields,
				query,
				page: {
					offset,
					limit,
				},
			});

			resolve({ success: true, data });
		} catch (error) {
			resolve({ success: false, error });
		}

		const resp = await getPipelineByCode(code);
		console.log('GET ONE', resp);
	});

export const downloadQuery = (code, sources, fields, query, offset = 0, limit = 10) =>
	new Promise(async (resolve) => {
		try {
			const data = await new DataService({
				url: `${dataPrepUrl}/${code}/download-query`,
				urlParams: '',
			}).create({
				sources,
				fields,
				query,
				page: {
					offset,
					limit,
				},
			});

			const file = await Axios.get(data.data.url, {
				responseType: 'blob', // Important
			});
			const blob = new Blob([file.data], { type: 'text/plain;charset=utf-8' });

			saveAs(blob, `${code}.csv`);

			resolve({ success: true, data });
		} catch (error) {
			resolve({ success: false, error });
		}

		const resp = await getPipelineByCode(code);
		console.log('GET ONE', resp);
	});

export const run = ({ code, operationId = null, download = false, offset = 0, limit = 10, tmp }) =>
	new Promise(async (resolve) => {
		let payload = {};
		if (!download)
			payload.page = {
				offset,
				limit,
			};
		if (operationId) payload.step = operationId;
		if (tmp) {
			if (tmp.query && tmp.mode === 'simple') tmp.query = JSON.stringify(tmp.query) || '';

			payload = { ...payload, ...tmp };
		}

		try {
			const result = await new DataService({
				url: `${dataPrepUrl}/${code}/run`,
				urlParams: '',
			}).create({ ...payload });

			if (!Array.isArray(result.data.preparedEvent)) resolve({ success: true, data: [] });

			let finalized = 0;
			for (let i = 0; i < result.data.preparedEvent.length; i++) {
				const task = result.data.preparedEvent[i];
				const task_id = task.task.id;
				let done = false;
				let counter = 0;

				while (!done && counter < 600) {
					await sleep(2000);
					const status = await getTaskStatus(task_id);
					if (status === 'SUCCEEDED') {
						finalized += 1;
						done = true;
					}
					if (status !== 'RUNNING' && status !== 'SUCCEEDED')
						throw {
							code: 'error_trying_reaching_resource',
						};
					counter += 5;
				}
			}
			resolve({ success: true, data: [] });
		} catch (error) {
			resolve({ success: false, error: error.response?.data?.error || error });
		}
	});

export const downloadFile = async (fileUrl, code, callback = () => {}, errorCallback = () => {}) => {
	try {
		const file = await Axios.get(fileUrl, {
			responseType: 'blob', // Important
		});

		const blob = new Blob([file.data], { type: 'text/plain;charset=utf-8' });

		saveAs(blob, `${code}.csv`);

		callback();
	} catch (error) {
		console.log(error);
		errorCallback();
	}
};

export const createPipeline = async (data) =>
	new Promise(async (resolve) => {
		try {
			const resp = await new DataService({
				url: `${dataPrepUrl}/`,
				urlParams: '',
				token: true,
			}).create(data);

			resolve({ success: true, data: resp.data.data });
		} catch (error) {
			resolve({ success: false, error });
		}
	});

export const updatePipeline = async (recordCode, data) =>
	new Promise(async (resolve) => {
		try {
			const resp = await new DataService({
				url: dataPrepUrl, // + "/",
				urlParams: '',
				token: true,
			}).update(recordCode, data);

			resolve({ success: true, data: resp });
		} catch (error) {
			resolve({ success: false, error });
		}
	});

/*

	return await new DataService({
		url: config.records.pipeline.url,
		urlParams: "?relation=" + clauseAllInfo,
		token: true,
	}).update(recordId, bodyParameters); */

export const deletePipeline = async (pipelineCode) =>
	new Promise(async (resolve) => {
		try {
			const resp = await new DataService({
				url: `${dataPrepUrl}`,
				urlParams: '',
				token: true,
			}).delete(pipelineCode);

			resolve({ success: true, data: resp });
		} catch (error) {
			resolve({ success: false, error });
		}
	});

const prepareDataBeforeRun = async (query, formRef, sourceDefined, callback = () => {}) => {
	if (!formRef?.current) return false;
	callback(true);

	const code = formRef.current.getFieldValue('code');

	const response = await updatePipeline(code, {
		query: JSON.stringify(query || {}) || '',
		deliveries: formRef.current.getFieldValue('deliveries'),
		advanced: false,
		mode: formRef.current.getFieldValue('mode'),
		sources: [...sourceDefined]?.map((src) => ({
			code: src.value,
			type: src.type,
			id: src.id,
			fields: src.fields,
		})),
	});
	if (!response.success) {
		callback(false);

		return false;
	}
	return code;
};

export const getPreviewData = async (
	query,
	tmpCode = null,
	currentValues,
	formRef,
	sourceDefined,
	callback = () => {},
	isSql = false
) => {
	const code = tmpCode || (await prepareDataBeforeRun(query, formRef, sourceDefined, callback));

	const tmpValues = { ...currentValues, ...formRef.current.getFieldsValue() };

	if (tmpCode) callback(true);

	if (!code) return false;
	return await run({
		code,
		offset: 0,
		limit: 10,
		operationId: isSql ? 'operation#sql#1' : 'operation#filter#1',
		tmp: tmpCode ? tmpValues : null,
	});
};

export const getDownloadData = async (
	query,
	tmpCode = null,
	currentValues,
	formRef,
	sourceDefined,
	callback = () => {},
	isSql = false
) => {
	const code = tmpCode || (await prepareDataBeforeRun(query, formRef, sourceDefined, callback));
	const tmpValues = { ...currentValues, ...formRef.current.getFieldsValue() };
	if (tmpCode) callback(true);
	if (!code) return false;
	return await run({ code, download: true, tmp: tmpCode ? tmpValues : null });
};
