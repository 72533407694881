import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
import ReportingService from 'ui/modules/Reporting/services/report.service';
import NotebookService from 'src/ui/modules/Reporting/services/notebook.service';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: 'Reporting',
	url: `${serverUrl}reporting/reports`,
	noParams: true,
	path: '/reports',
	pageTitle: {
		list: 'Reports',
		action: {
			create: 'New Report',
			edit: 'Edit Report',
			execute: 'Execute Report',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Type',
			field: 'type',
		},
		{
			headerName: 'Created at',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated at',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	getService: () => ({
		instance: () => ReportingService.getInstance(),
		bulkDelete: async (data: { ids: string[] }) => {
			const { ids } = data;
			// TODO: just temporary until we implement bulk delete => will use ReportingService.deleteOne instead of ReportingService.deleteOne
			try {
				for (let index = 0; index < ids.length; index++) {
					const id = ids[index];
					await NotebookService.close(id);
					return ReportingService.deleteOne(id);
				}
			} catch (e) {}
		},
	}),
};
