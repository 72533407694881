export enum Icons {
	Default = 'Default',
	AllDashboards = 'AllDashboards',
	Contracts = 'Contracts',
	Copy = 'Copy',
	Dashboard = 'Dashboard',
	DataPrep = 'DataPrep',
	Dataset = 'Dataset',
	DatasetRef = 'DatasetRef',
	DnextLogo = 'DnextLogo',
	DnextMiniLogo = 'DnextMiniLogo',
	Documentation = 'Documentation',
	Emails = 'Emails',
	EmailsRuleset = 'EmailsRuleset',
	FilesManagement = 'FilesManagement',
	FilesStorage = 'FilesStorage',
	Forecast = 'Forecast',
	Freight = 'Freight',
	Fundamentals = 'Fundamentals',
	GlobalRef = 'GlobalRef',
	Grains = 'Grains',
	Home = 'Home',
	Labels = 'Labels',
	Logout = 'Logout',
	Organization = 'Organization',
	OrganizationOverview = 'OrganizationOverview',
	Production = 'Production',
	RefData = 'RefData',
	RefManagement = 'RefManagement',
	References = 'References',
	Reporting = 'Reporting',
	ResourceGroup = 'ResourceGroup',
	Settings = 'Settings',
	Sugar = 'Sugar',
	Support = 'Support',
	SystemEntities = 'SystemEntities',
	Tradeflow = 'Tradeflow',
	Users = 'Users',
	Weather = 'Weather',
	BadgeFilled = 'BadgeFilled',
	BadgeWithStar = 'BadgeWithStar',
	Livestock = 'Livestock',
	CategoryGroup = 'CategoryGroup',
	Categories = 'Categories',
	Workflows = 'Workflows',
	DashboardWithPieChart = 'DashboardWithPieChart',
	Sheets = 'Sheets',
	BasicSheet = 'BasicSheet',
	TradeMatrix = 'TradeMatrix',
	Table = 'Table',
	Matrix = 'Matrix',
	WheatEarFilled = 'WheatEarFilled',
	WheatEarStrokes = 'WheatEarStrokes',
	Fuel = 'Fuel',
	Fertilizer = 'Fertilizer',
	Admin = 'Admin',
	PowerFilled = 'PowerFilled',
	PendingDoc = 'PendingDoc',
	KeyLock = 'KeyLock',
	Hourglass = 'Hourglass',
	Pending = 'Pending',
	Blocked = 'Blocked',
	PowerStroke = 'PowerStroke',
	AnchorFilled = 'AnchorFilled',
	AnchorStroke = 'AnchorStroke',
	NPKFertilizer = 'NPKFertilizer',
	DataPrepConfig = 'DataPrepConfig',
	DataDevelopmentStroke = 'DataDevelopmentStroke',
	DataConfig = 'DataConfig',
	DataDevelopmentFilled = 'DataDevelopmentFilled',
	DataTransform = 'DataTransform',
	EditWorkflow = 'EditWorkflow',
	ViewWorkflow = 'ViewWorkflow',
	EditFlow = 'EditFlow',
	ViewFlow = 'ViewFlow',
	ScriptLibrary = 'ScriptLibrary',
	BlackFryersEUSolid = 'BlackFryersEUSolid',
	BlackFryersEUStroked = 'BlackFryersEUStroked',
	BlackFryersEUNoBorder = 'BlackFryersEUNoBorder',
	Biofuels = 'Biofuels',
}
