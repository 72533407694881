import { createContext, useContext } from 'react';
import { ILabelsContext } from 'src/types/interfaces/route.interface';
import { ILabel } from 'ui/modules/Core/types/interfaces/label.interface';

const LabelsListContext = createContext<ILabelsContext>({
	getLabelsListValues: (recordType: string): ILabel[] => {
		return [];
	},
	setLabelsListValues: (recordType: string, values: ILabel[]): void => {},
	isLabelsListLoaded: (recordType: string): boolean => {
		return false;
	},
});

export function useLabelsContext(): ILabelsContext {
	return useContext(LabelsListContext) as unknown as ILabelsContext;
}

export default LabelsListContext;
