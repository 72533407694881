import React from 'react';
import { Button } from 'antd';
import { data, getApiPrefix, server } from 'src/utils/api-prefix.helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPause, faStop } from '@fortawesome/free-solid-svg-icons';
// services
import { bulkDeleteDataset, getDatasetService } from 'ui/modules/Core/services/dataset.service';
// helpers
import { min } from 'lodash';
import { pushNotification } from 'src/utils/PushNotification';
// types & interfaces
import { ICellRendererParams } from 'ag-grid-community';
import { IDataset } from 'ui/modules/Core/types/interfaces/dataset.interface';
import { IWebSocketResponse } from 'ui/modules/Core/types/interfaces/socket.interface';
// Shared constants and translation
import model_workflow from 'src/locales/en/model/workflow';
import { stepsForFilter as WorkflowSteps } from 'ui/modules/Core/constants/workflow/workflow.constant';
// shared configs
import configInterface from 'src/interface.config';

const serverUrl = `${server}${getApiPrefix()}/`;
export default {
	referenceData: false,
	iconName: 'Dataset',
	url: `${serverUrl}${data}/datasets`,
	urlParams:
		'relation=[user,data_steward,source,location,product,product_category,category,workflow,source_owner,source_channel,labels]',
	urlOneDatasetParams:
		'relation=[user,data_steward,source,location,location_group,product,product_category,category,workflow,source_owner,source_channel,dataset_field,dataset_version,labels]',
	path: '/datasets',
	pageTitle: {
		list: 'Datasets',
		action: {
			create: 'New dataset',
			edit: 'Edit dataset',
		},
	},
	actions: [
		<Button
			onClick={function () {
				this.props.history.push('/search');
			}}
			key="data_catalog"
		>
			Data catalog
		</Button>,
	],
	onReceiveNotification(event: IWebSocketResponse) {
		if (
			event.code === 'workflow_step_started' ||
			event.code === 'workflow_succeeded' ||
			event.code === 'file_loading_completed' ||
			event.code === 'workflow_failed' ||
			event.code === 'script_execution_failed' ||
			event.code === 'file_conversion_failed' ||
			event.code === 'invalid_format' ||
			event.code === 'invalid_file' ||
			event.code === 'no_matching_email'
		) {
			let newStatus = 'running';
			let lastStep = event.details;
			if (event.code === 'workflow_failed') {
				newStatus = 'error';
			}
			if (
				event.code === 'script_execution_failed' ||
				event.code === 'file_conversion_failed' ||
				event.code === 'invalid_format' ||
				event.code === 'invalid_file' ||
				event.code === 'no_matching_email'
			) {
				newStatus = 'error';
				lastStep = 'loading';
			}

			/* 	execute workflow auto (from list workflow/ incoming data/ btn save and execute until the end)
					last_status will be updated to success after workflow_succeeded event
				execute workflow manual (from worfklow form step by step) 
					last_status will be updated only with end execute button */
			if (event.code === 'workflow_succeeded' && localStorage.getItem('woto_execute_mode') === 'auto') {
				newStatus = 'success';
			}

			this._alterRowData(
				event.object_id,
				{ last_status: newStatus, last_step: lastStep },
				null,
				false,
				'workflow'
			);
		}

		if (event.code !== 'workflow_step_ended' && event.type !== 'system') {
			pushNotification(
				this.props.addToast,
				this.props.t(`model.workflow.${event.type}.${event.code}`),
				event.type,
				(event.type === 'error' || event.code === 'script_notification') && event.details
					? JSON.stringify(event?.details)
					: ''
			);
		}
	},
	columns: [
		{
			headerName: 'State',
			field: 'workflow.state',
			type: 'stateWorkflow',
			typeParams: {
				icons: {
					runnable: <FontAwesomeIcon icon={faCircle} className=" mr-0 recording" transform="grow-1" />,
					standby: <FontAwesomeIcon icon={faPause} className="mr-0" transform="grow-1" />,
					stopped: <FontAwesomeIcon icon={faStop} className="mr-0" transform="grow-1" />,
				},
			},
			minWidth: 80,
			width: 80,
			filter: true,
			values: ['standby', 'runnable', 'stopped'],
		},
		{
			headerName: 'Grade',
			field: 'stage',
			type: 'badge',
			minWidth: 80,
			width: 80,
			typeParams: {
				staticValues: ['None', 'Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', null],
			},
		},
		{
			headerName: 'Shared',
			field: 'shared',
			type: 'boolean',
			minWidth: 80,
			width: 80,
		},
		{
			headerName: 'Shareable',
			field: 'can_share_data',
			type: 'boolean',
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 200,
		},
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Trigger',
			field: 'workflow.trigger_type',
			filterValueGetter: ({ data }: ICellRendererParams) => {
				const trigger = data?.workflow?.trigger_type;
				if (!trigger || trigger === 'none') {
					return model_workflow.list.trigger.none;
				}
				return (model_workflow.list.trigger as Record<string, string>)[trigger];
			},
			cellRenderer(params: ICellRendererParams) {
				if (params.data?.shared) return '_';
				if (!params.value || params.value === 'none') return model_workflow.list.trigger.none;
				return (model_workflow.list.trigger as Record<string, string>)[params.value];
			},
			minWidth: 110,
		},
		{
			headerName: 'Status',
			field: 'workflow.last_status',
			type: 'label',
			minWidth: 90,
			typeParams: {
				null_value: '_',
			},
		},
		{
			headerName: 'Execution path',
			field: 'workflow',
			type: 'executionPath',
			filter: 'agSetColumnFilter',
			filterParams: {
				values: WorkflowSteps.map((step) => step.title),
				buttons: ['reset'],
				applyMiniFilterWhileTyping: true,
				cellRenderer: 'filterExecutionPathRenderer',
				suppressSorting: true,
			},
			filterValueGetter: (params: ICellRendererParams) => {
				let lastStep = params?.data?.workflow?.last_step;
				if (lastStep === 'loading') {
					lastStep = 'exporting';
				} else if (lastStep === 'exporting') {
					lastStep = 'loading';
				}
				let step = WorkflowSteps.find((item) => item.id === lastStep);
				return step?.title || null;
			},
			minWidth: 190,
		},
		{
			headerName: 'Loader',
			field: 'workflow.loader_type',
			filterValueGetter: ({ data }: ICellRendererParams) => {
				const loader = data?.workflow?.loader_type;
				if (!loader || loader === 'none') {
					return model_workflow.list.loader.none;
				}
				return (model_workflow.list.loader as Record<string, string>)[loader];
			},
			cellRenderer(params: ICellRendererParams) {
				if (params.data?.shared) return '_';
				if (!params.value || params.value === 'none') return model_workflow.list.loader.none;
				return (model_workflow.list.loader as Record<string, string>)[params.value];
			},
			minWidth: 135,
		},
		{
			headerName: 'Frequency',
			field: 'frequency',
			minWidth: 135,
		},
		{
			headerName: 'Labels',
			field: 'labels',
			type: 'labels',
			minWidth: 200,
		},
		{
			headerName: 'Location',
			field: 'location',
			minWidth: 100,
			width: 100,
			type: 'reference',
		},
		{
			headerName: 'Product',
			field: 'product',
			minWidth: 100,
			width: 100,
			type: 'reference',
		},
		{
			headerName: 'Category',
			field: 'category',
			minWidth: 160,
			width: 160,
			type: 'reference',
		},
		{
			headerName: 'Source',
			field: 'source',
			type: 'reference',
			minWidth: 180,
			width: 180,
		},
		{
			headerName: 'Data steward',
			field: 'data_steward',
			minWidth: 150,
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Data owner',
			field: 'source_owner',
			minWidth: 150,
			type: 'reference',
			referenceType: 'source',
		},
		{
			headerName: 'Data channel',
			field: 'source_channel',
			minWidth: 150,
			type: 'reference',
			referenceType: 'source',
		},
		{
			headerName: 'Created by',
			field: 'created_by_user',
			minWidth: 150,
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Creation date',
			field: 'created_at',
			headerTooltip: 'Date when dataset was created',
			type: 'date',
			minWidth: 200,
		},
		{
			headerName: 'Last execution',
			field: 'workflow.last_run_at',
			headerTooltip: 'Last execution date of the workflow',
			minWidth: 200,
			width: 200,
			type: 'date',
		},
		{
			headerName: 'Last data update',
			field: 'last_data_change_date',
			type: 'date',
			headerTooltip: 'Last date at which the number of rows in the dataset has changed',
			minWidth: 200,
		},
		{
			headerName: 'Last Loading Date',
			field: 'last_loaded_data_date',
			type: 'date',
			headerTooltip: 'Last date of data loading in the workflow',
			minWidth: 220,
		},
		{
			headerName: 'Last extracted data Date',
			field: 'last_extracted_data_date',
			type: 'date',
			headerTooltip: 'Last date of data extraction in the workflow',
			minWidth: 220,
		},
		{
			headerName: 'Next data extraction trigger',
			field: 'workflow.scheduled_tasks',
			type: 'datetime',
			headerTooltip: 'Next scheduled date for data fetching process execution',
			valueGetter(params: { data: IDataset }) {
				if (params.data?.workflow?.scheduled_tasks?.length > 0) {
					const data: any = new min(params.data.workflow.scheduled_tasks, 'next_run_planned_at');
					return {
						value: data.next_run_planned_at,
						timezone: data.timezone,
					};
				}
				return null;
			},
			minWidth: 200,
		},
	],
	sortModel: [
		{
			colId: 'dataset.category',
			sort: 'asc',
		},
		{
			colId: 'dataset.name',
			sort: 'asc',
		},
	],
	navigateByCode: true,
	customCreate: true,
	canbeCloned: true,
	canExecuteWorkflow: true,
	showCheckBox: true,
	canChangeState: true,
	canChangeTags: true,
	loadLastUpdate: true,
	canFilter: ['location', 'product'],
	forecast_type: ['FORECAST', 'PROD-FORECAST'],
	commonTabsInViewAndEdit: ['general', 'structure', 'mapping', 'versions', 'data-health', 'data-catalog'],
	moreActions: [
		{
			action: 'View workflow',
			icon: 'ViewWorkflow',
			permission: 'workflow.can_read',
			onClick: (data: IDataset) => {
				const { id } = data.workflow;
				if (!id) return;
				window.open(`/workflows/${id}`, 'blank');
			},
		},
		{
			action: 'Edit workflow',
			icon: 'EditWorkflow',
			permission: 'workflow.can_update',
			onClick: (data: IDataset) => {
				const { id, last_step } = data.workflow;
				if (!id) return;
				window.open(
					`/workflows/${id}/${configInterface.editRecordUrlValue}/${last_step || 'loading'}`,
					'blank'
				);
			},
		},
	],
	getService: () => ({
		instance: getDatasetService,
		bulkDelete: bulkDeleteDataset,
	}),
};
