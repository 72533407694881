import React from 'react';
// constants
import { emailColumns } from 'src/ui/modules/Storage/constants/email.constant';
// utils
import { server, getApiPrefix, storage } from 'src/utils/api-prefix.helper';
import _ from 'lodash';
//components
import EmailRulesetButton from 'src/ui/modules/Storage/components/Emails/components/emailRulesetButton';
import { bulkDeleteEmail, getListEmails } from 'src/ui/modules/Storage/services/email.service';

export default {
	referenceData: false,
	iconName: 'Emails',
	url: `${server}${getApiPrefix()}/${storage}/email`,
	urlParams: 'order=[["received_at", "DESC"]]',
	split: 5000,
	path: '/emails',
	pageTitle: {
		list: 'Emails',
		help: 'Any email sent to bot+{ORGANIZATION_ID}@data.dnext.io will appear here. The size of the email can not exceed 25MB',
		action: {
			create: 'New email',
			edit: 'Edit email',
		},
	},
	columns: emailColumns,
	actions: [(history, translate) => <EmailRulesetButton history={history} translate={translate} />],
	getService: () => ({
		instance: getListEmails,
		bulkDelete: bulkDeleteEmail,
	}),
};
