export default {
	name: 'Data Prep',
	name_plural: 'Data Prep',
	new_record_title: 'New Data Prep',
	base: {
		name: 'Data Prep',
		name_plural: 'Data Prep',
	},
	actions: {
		create_new: 'Create a new Data Prep',
	},
	no_x: 'No Data Prep',
};
