export default {
	name: 'Report',
	name_plural: 'Reports',
	new_record_title: 'Create Report',
	types: {
		markdown: 'Markdown',
		code: 'Code',
		markdown_plural: 'Markdowns',
		code_plural: 'Codes,',
	},
	labels: {
		name: 'Name',
		schedule: 'Export Schedule',
		type: 'Type',
		report: 'Report',
		status: 'Status',
		path: 'Path',
		kernel_id: 'kernel_id',
		session_id: 'session_id',
		created_at: 'Created At',
		updated_at: 'Updated At',
		closed_connection: 'Connection closed',
		opened_by: 'Opened By',
		created_by: 'Created By',
	},
	dialog: {
		close_title: 'Close Notebook',
		close_dialog:
			'Are you sure you want to close the notebook? Please make sure to save your work before closing to avoid losing your changes.',
		delete_cell: 'Are you sure to delete cell?',
		publish_title: 'Publish Notebook',
		publish_dialog:
			'You can either export the notebook as a file, or export as a file and add it to the files section. Which one do you want to do?',
	},
	actions: {
		create_new: 'Create Report',
		get_one: 'Get Report',
		update: 'Update Report',
		delete_report: 'Delete Report',
		delete: 'Delete',
		delete_markdown: 'delete Markdown',
		save: 'Save',
		save_notebook: 'Save Notebook',
		run: 'Run notebook',
		copy: 'Copy',
		open: 'Open notebook',
		reopen: 'Reload notebook',
		close: 'Close',
		close_notebook: 'Close Notebook',
		open_notebook: 'Open Notebook',
		save_and_close: 'Save and Close',
		save_and_close_notebook: 'Save and Close Notebook',
		publish: 'Publish',
		publish_and_save: 'Publish and add to files',
		publish_notebook: 'Publish Notebook',
		save_all: 'Save All',
		cancel: 'Cancel',
	},
	errors: {
		access_denied: 'Access denied',
		error_occurred: 'An error occurred when retrieving reports',
		error_occurred_notebook: 'An error occurred while opening notebook',
		error_get_report: 'An error has occurred when getting report detail',
		error_create_report: 'An error has occurred when creating report',
		error_update_report: 'An error has occurred when updating report',
		error_delete_report: 'An error has occurred when deleting report',
		error_saved_notebook: 'An error has occurred when saving notebook',
		error_publish_notebook: 'An error has occurred when publishing notebook',
		error_access_notebook: 'Cannot access this notebook at this moment',
		error_open_notebook: 'This notebook cannot be opened',
		error_close_notebook: 'This notebook cannot be closed',
		error_connection: 'There are some problems with your WS url',
		warning_run_cell: 'Cannot run this notebook at this moment',
		not_authorized: "You're not authorized to access this report",
		resource_not_found: 'Report not found',
		report_already_opened: 'Report is already opened',
	},
	success: {
		copied: 'Copied !',
		created: 'Report created Successfully',
		updated: 'Report updated Successfully',
		deleted: 'Report deleted Successfully',
		closed: 'Notebook closed Successfully',
		saved_and_closed: 'Notebook saved and closed Successfully',
		saved: 'Notebook saved Successfully',
		published: 'Notebook published Successfully',
	},
};
