export default {
	name: 'Support Guide',
	actions: {
		ask_help: 'Ask for help',
		see_more: 'see more',
	},
	labels: {
		articles_in_section: 'Articles in this section',
		no_articles_found: 'No articles found',
		search_text: 'search text',
	},
};
