import { faPlug } from '@fortawesome/pro-light-svg-icons';
import { basePath, server } from 'src/utils/api-prefix.helper';
// configs
import configUser from 'src/ui/modules/Core/config/user.config';
import configLabel from 'src/ui/modules/Core/config/label.config';
import configVoyage from 'ui/modules/Freight/config/voyage.config';
import configEstimate from 'ui/modules/Freight/config/estimate.config';
import configDataset from 'src/ui/modules/Core/config/dataset.config';
import configTradeFlow from 'ui/modules/Fundamentals/config/tradeflow.config';
import configForecast from 'ui/modules/Fundamentals/config/forecast.config';
import configProduction from 'ui/modules/Fundamentals/config/production.config';
import configWorkflow from 'src/ui/modules/Core/config/workflow.config';
import configCategory from 'src/ui/modules/Core/config/category.config';
import configFreight from 'src/ui/modules/Freight/config/freight.config';
import configDashboard from 'src/ui/modules/Dashboards/config/dashboard.config';
import configEmail from 'src/ui/modules/Storage/config/email.config';
import configEmailRuleset from 'src/ui/modules/Storage/config/emailRuleset.config';
import configReferences from 'ui/modules/References/config/references.config';
import configSystemEntity from 'ui/modules/Core/config/system-entity.config';
import configReport from 'ui/modules/Reporting/config/reporting.config';
import configPipeline from 'ui/modules/Pipeline/configs/pipeline.config';
import configFile from 'src/ui/modules/Storage/config/file.config';
import configContract from 'ui/modules/OrganizationSettings/configs/contract.config';
import configResourceGroup from 'ui/modules/OrganizationSettings/configs/resource-group.config';
import configOrganizations from 'ui/modules/OrganizationSettings/configs/organizations.config';
import configReferenceManagement from 'ui/modules/OrganizationSettings/configs/reference-management.config';
import configScriptLibrary from 'src/ui/modules/Core/config/script-library.config';

// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	dashboard: configDashboard,
	forecast: configForecast,
	production: configProduction,
	trade_flow: configTradeFlow,
	pipeline: configPipeline,
	dataset: configDataset,
	workflow: configWorkflow,
	user: configUser,
	email: configEmail,
	email_ruleset: configEmailRuleset,
	reference_management: configReferenceManagement,
	contract: configContract,
	resource_group: configResourceGroup,
	organization: configOrganizations,
	files: configFile,
	label: configLabel,
	category: configCategory,
	...configReferences,
	dataset_global_mapping: {
		referenceData: false,
		iconName: 'GlobalRef',
		url: `${baseApiUrl}dataset-global-mapping`,
		urlParams: '',
		path: '/global-reference-mapping',
		pageTitle: {
			list: 'Global Reference mapping',
			action: {
				create: '',
				edit: '',
			},
		},
	},
	dataset_field_mapping: {
		referenceData: false,
		iconName: 'DatasetRef',
		url: `${baseApiUrl}dataset-field-mapping`,
		urlParams: '',
		path: '/dataset-reference-mapping',
		pageTitle: {
			list: 'Dataset Reference Mapping',
			action: {
				create: '',
				edit: '',
			},
		},
	},
	freight: configFreight,
	voyage: configVoyage,
	estimate: configEstimate,
	system_entity: configSystemEntity,
	script_library: configScriptLibrary,
	report: configReport,
	api_key: {
		referenceData: false,
		iconName: faPlug,
		url: `${baseApiUrl}api-key`,
		path: '/api_keys',
		columns: [
			{
				headerName: 'Name',
				field: 'name',
				hidden: false,
				order: 1,
			},
			{
				headerName: 'Created by',
				minWidth: 150,
				field: 'user',
				type: 'reference',
				referenceType: 'user',
			},
			{
				headerName: 'Created',
				field: 'createdAt',
				type: 'datetime',
			},
		],
		pageTitle: {
			list: 'API keys',
			action: {
				create: 'New api key',
				edit: 'Edit api key',
			},
		},
		canCreate: false,
		canEdit: false,
		canDelete: false,
	},
};
