import { DataService } from 'src/utils';
import { saveAs } from 'file-saver';
import Axios from 'axios';
import { fundamentals, getApiPrefix, server } from 'src/utils/api-prefix.helper';
import {
	ForecastType,
	IBulkUpdateForecastPayload,
	IForecast,
	IForecastPayload,
} from 'ui/modules/Core/types/interfaces/forecast.interface';
import { IPreviewPayload } from 'ui/modules/Core/types/interfaces/dataset.interface';

const forecastUrl = `${server}${getApiPrefix()}/${fundamentals}/forecasts`;

export const getForecastService = (version = true, old_sharing = true) => {
	// TODO: params '?old_sharing=true' must be removed after compliance work is completely done
	let queryParams = `?old_sharing=${!!old_sharing}`;
	if (version) queryParams += '&forecast_version=true';
	return new DataService({
		url: `${forecastUrl}${queryParams}`,
		urlParams: '',
		token: true,
	});
};

export const updateForecastService = async (code: string, payload: IForecastPayload) => {
	return await new DataService({
		url: forecastUrl,
		urlParams: '',
	}).update(code, payload);
};
export const createForecastService = async (payload: IForecastPayload) => {
	return await new DataService({
		url: forecastUrl,
		urlParams: '',
	}).create(payload);
};

// TODO: this is a temporary fix, it should be replaced with delete bulk API
export const bulkDeleteForecast = async (payload: { codes: string[] }) => {
	const service = new DataService({
		url: forecastUrl,
		urlParams: '',
		token: true,
	});
	try {
		await Promise.all(payload.codes.map((code: string) => service.delete(code)));
	} catch (err) {
		console.error('error:', err);
	}
};

export const getForecastByType = async (type: ForecastType, formatted = false, params = '') => {
	const result = await new DataService({
		url: `${forecastUrl}${params}`,
		urlParams: '',
		token: true,
	}).getAll();
	const filteredResult = result.data?.result.filter((c: IForecast) => c.type === type);
	if (!formatted) return filteredResult;
	return filteredResult.map((el: IForecast, i: number) => ({
		...el,
		code: el.code,
		priority: i,
		key: el.id,
		name: el.name,
		title: `${el.name} (${el.code})`,
		description: el.name,
		type: el.type,
	}));
};

export const getOneForecastService = (code: string) => {
	// TODO: params '?old_sharing=true' must be removed after compliance work is completely done
	return new DataService({
		url: `${forecastUrl}/${code}?old_sharing=true`,
		urlParams: '',
		token: true,
	}).getAll();
};

export const getForecastDataByCode = async (
	code: string,
	type: string,
	refresh: boolean,
	start_date: string,
	end_date: string,
	start_month: string,
	version: string
) => {
	try {
		const result = await new DataService({
			url: `${forecastUrl}/${type}/${code}/get`,
			urlParams: '',
		}).create({
			minForecastDate: start_date,
			maxForecastDate: end_date,
			startMonth: start_month,
			useCache: refresh,
			version: version,
		});

		return result;
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const downloadForecast = async (code: string, type: string) => {
	try {
		const result = await new DataService({
			url: `${forecastUrl}/${type}/${code}/download`,
			urlParams: '',
			token: true,
		}).getAll();
		const fileUrl = result.data.result.url;
		if (fileUrl) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});
			const blob = file.data;
			saveAs(blob, `${code}_${new Date().getTime()}.csv`);
			return true;
		}
	} catch (err) {
		return null;
	}
};

export const getAvailableForecasts = async (id: string, selectFormat = false) => {
	const response = await getForecastService().getAll();
	const data = response?.data?.result?.filter((c: IForecast) => c.id !== id);

	if (selectFormat) {
		return data.map((el: IForecast) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			type: el.type,
		}));
	}
	return data;
};

export const bulkUpdateForecastService = async (
	type: ForecastType,
	code: string,
	payload: IBulkUpdateForecastPayload
) => {
	return await new DataService({
		url: `${forecastUrl}/${type}/${code}/bulk-update`,
		urlParams: '',
	}).create(payload);
};

export const getForecastPreview = async (code: string, type: ForecastType, payload: IPreviewPayload) => {
	const result = await new DataService({
		url: `${forecastUrl}/${type}/${code}/preview`,
		urlParams: '',
	}).create(payload);
	return result?.data || [];
};

export const getForecastDataService = async (
	code: string,
	type: ForecastType,
	payload: {
		minForecastDate: string;
		maxForecastDate: string;
		startMonth: number;
		useCache?: boolean;
		version?: string;
		items?: string[];
	}
) => {
	return new DataService({
		url: `${forecastUrl}/${type}/${code}/get`,
		urlParams: '',
	}).create(payload);
};

export const publishForecastDataService = async (
	code: string,
	type: ForecastType,
	payload: {
		name: string;
		tradeflowCode: string;
		forecasts: string[];
		items: string[];
	}
) => {
	return new DataService({
		url: `${forecastUrl}/${type}/${code}/publish `,
		urlParams: '',
	}).create(payload);
};

export const oldPublishForecastDataService = async (payload: {
	code: string;
	name: string;
	tradeflowCode: string;
	forecasts: string[];
}) => {
	return new DataService({
		url: `${server}fundamentals/tradeflow/forecast/publish`,
		urlParams: '',
	}).create(payload);
};

export const updateForecastDataService = async (
	code: string,
	type: ForecastType,
	payload: {
		key: string;
		bucket: string;
	}
) => {
	return new DataService({
		url: `${forecastUrl}/${type}/${code}/update `,
		urlParams: '',
	}).create(payload);
};
