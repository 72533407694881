import { faAnchor } from '@fortawesome/pro-light-svg-icons';
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
import { bulkDeleteEstimateService, getAllEstimateService } from 'ui/modules/Freight/services/estimate.service';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	pageTitle: {
		list: 'Estimates',
		action: {
			edit: 'Edit estimate',
		},
	},
	path: '/freight/estimates',
	description: 'Freight',
	url: `${serverUrl}freight/estimates`,
	noParams: true,
	urlParams: '',
	iconName: faAnchor,
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Commodity',
			field: 'cargo_product_code',
		},
		{
			headerName: 'Commodity 2',
			field: 'cargo_product2_code',
		},
		{
			headerName: 'Vessel',
			field: 'vessel_name',
		},
		{
			headerName: 'Load Port',
			field: 'port_5_itinerary_port_code',
		},
		{
			headerName: 'Top Off Port',
			field: 'port_6_itinerary_port_code',
		},
		{
			headerName: 'Discharge Port',
			field: 'port_10_itinerary_port_code',
		},
		{
			headerName: 'Discharge Port 2',
			field: 'port_11_itinerary_port_code',
		},
		{
			headerName: 'Routing port',
			field: 'routing_port',
		},
		{
			headerName: 'Origin port',
			field: 'port_1_itinerary_port_code',
		},
	],
	getService: () => ({
		instance: getAllEstimateService,
		bulkDelete: bulkDeleteEstimateService,
	}),
};
