export default {
	title: 'Freight',
	title_plural: 'Freights',
	breadcrumbs: {
		voyages: 'Voyages',
		estimates: 'Estimates',
	},
	titles: {
		dashboard: 'Net Freight Rates',
		voyages_list: 'Voyages',
		estimates_list: 'Estimates',
		batches_list: 'Batches',
		forward_customization: 'Forward customization',
		batches: 'Batches',
		calculate_spot: 'Calculate spot',
		calculate_rate: 'Calculate freight rates (Spot + Forwards)',
		first_route: 'First Route',
		second_route: 'Second Route',
		second_route_mode: 'Second Route Mode',
	},
	labels: {
		spot_dates: 'Spot Dates',
		spot_date: 'Spot Date',
		cur_month_factor: 'Current Month Factor',
		near_month_factor: 'Near Month Factor',
		filters: 'Filters',
		is_visible: 'Is Visible',
		not_visible: 'Not Visible',
		visible: 'Visible',
		filter: 'Filter',
		total_records: 'Total record(s)',
		all: 'ALL',
		values: 'Values',
	},
	voyage_input: {
		ports_infos: {
			port_status: 'Port status',
			port_name: 'Port name',
			berth: 'Berth',
		},
		at_sea: {
			name: 'At sea',
			draft: {
				draft: 'Draft',
				density: 'Density',
				vlsfo_dist: 'VLSFO dist',
				ulsfo_dist: 'ULSFO dist',
				sea_margin: 'Sea margin (%)',
			},
			zone: {
				ll_zone: 'LL zone',
				eca_neca: 'ECA/NECA',
			},
			time: {
				vl_time: 'VLSFO time',
				ul_time: 'ULSFO time',
				total_time: 'Total time',
			},
			bkr_cons: {
				vlsfo_mass: 'VLSFO cons',
				lsmgo_mass: 'LSMGO cons',
				total_mass: 'Total mass',
			},
		},
		at_port: {
			name: 'At port',
			time: {
				intake: 'Intake',
				rate: 'Rate',
				terms: 'Terms',
				factor: 'Factor',
				turn_time: 'Turn time',
				idle_time: 'Idle time',
				total_time: 'Total time',
				expense: 'Expense',
			},
			bkr_cons: {
				intake: 'Intake',
				vlsfo_mass: 'VLSFO cons',
				lsmgo_mass: 'LSMGO cons',
				total_mass: 'Total mass',
				expense: 'Expense',
			},
		},
		rules: {
			invalid_estimate: 'Missing Data for Estimate Input:',
			invalid_steps_voyage: 'Please revise your voyage plan to meet these rules:',
			invalid_itinerary_estimate: 'Please revise your estimate plan to meet these rules:',
			rule_1: {
				title: 'One Load and Discharge Port:',
				description: 'Ensure one load port before one discharge port.',
			},
			rule_2: {
				title: 'Optional Top-Off Port:',
				description: 'Include at most one top-off port right after the load port.',
			},
			rule_3: {
				title: 'Maximum 14 Ports:',
				description: 'Limit your voyage to no more than 14 ports.',
			},
			rule_4: {
				title: 'Ports Before and After: ',
				description: 'Up to 4 ports before the load, 3 between load and discharge, and 3 after discharge.',
			},
			adjust_submit: 'Adjust your plan and resubmit.',
			adjust_estimate_submit:
				'You cannot proceed with saving this estimate input because some required information is missing.',
		},
		vessel_area: 'Vessel Area',
		time_sum_up: 'Time Sum Up',
		blkr_area: 'Blkr Area',
		misc_operating_cos: 'Misc Operating Cost',
		expense_sum_up_area: 'Expense Sum Up Area',
		owner_area: 'Owners Area',
	},
	additional_info: {
		trade_group: 'Trade Group',
		benchmark: 'Benchmark',
		from: 'from',
		to: 'to',
	},
	result_table: {
		delivery: 'Delivery',
		vessel_hire: 'Vessel Hire',
		gross_bb: 'Gross BB',
		fio_result: 'FIO Result',
		fio_rate: 'Fio rate',
		net_tce: 'Net TCE',
	},
	blkr_area: {
		bunkers: 'Bunkers',
		vlsfo: 'VLSFO',
		lsmgo: 'LSMGO',
		carbon: 'Carbon',
	},
	actions: {
		new: 'new',
		save: 'Save',
		run: 'Dry Run',
		add_filter: 'Add New Batch',
		edit_filter: 'Edit Batch',
		save_later: 'Save later',
		calculate: 'Calculate',
		reset: 'Reset Manual Changes',
		cancel: 'Cancel',
		delete_voyage: 'Delete voyage',
		delete_batch: 'Delete batch',
		clone_batch: 'Clone batch',
		add_row: 'Add Row',
		remove_row: 'Remove row',
		forward_customization: 'Forward customization',
		second_route: 'Second route',
		calculate_spot: 'Calculate spot',
		calculate_rate: 'Calculate freight rates',
		add_batch: 'Add new batch',
		edit_batch: 'Edit batch',
		ok: 'OK',
	},
	confirms: {
		delete_voyage: 'Are you sure to delete this voyage?',
		delete_batch: 'Are you sure to delete this batch?',
		delete_row: 'Are you sure you want to delete this row? Deleting it will remove all following steps.',
		trigger_calculation1: 'Are you sure you want to trigger spot calculation on ',
		trigger_calculation2: "It'll impact every voyage. Do you want to proceed?",
		trigger_calculate_rate_confirm1: 'Are you sure you want to trigger rates calculation on ',
		trigger_calculate_rate_confirm2: "It'll impact every voyage and every forward. Do you want to proceed?",
	},
	messages: {
		error_request: 'Oops! Something went wrong. Please try again later',
		warn_request: 'Wrong id, please verify the voyage id',
		success_add_voyage: 'Voyage was created successfully',
		success_update_voyage: 'Voyage was updated successfully',
		success_calculate_spot: 'Your spot calculation have been triggered successfully',
		info_calculate_spot: 'Information: It might take some time for the results to come in.',
		success_calculate_rate: 'Your rate calculation have been triggered successfully',
		info_calculate_rate: 'Information: It might take some time for the results to come in.',
		nothing_to_show: 'Unable to fetch data. Retry later or contact support',
		no_data: 'No data',
		unsaved_changes: 'Changes Detected',
		changed_values: 'The following values have changed:',
		save_changes: 'Please review and submit these changes to proceed.',
		attention: 'Attention!',
		edit_second_route: 'You are about to update all the voyage changes. Do you want to continue?.',
		edit_second_route_estimate:
			'This change is temporary and will only be saved once the estimate is saved. Please keep this in mind.',
		cancel_second_route_estimate: 'You are about to reset all the second route changes. Do you want to continue?.',
		cancel_edit_voyage: 'You are about to reset all the voyage changes. Do you want to continue?.',
		reset_edit_estimate:
			'You are about to reset all changes made to the determined input fields. These fields will be updated with values derived from the freight information. The changes will be reflected in your form and saved to the database. Are you sure you want to proceed?',
		voyage_plan_error: 'Voyage Plan Error',
		estimate_plan_error: 'Estimate Plan Error',
		maximum_steps: "You've reached the maximum number of steps (14) in your ports.",
		empty_previous_step: 'The last step is empty. Please fill it before adding a new one',
		no_more_path_step: "You've reached the limit of 4 defined paths. No further paths can be added.",
		must_submit: 'Voyage has been updated. Please submit to initiate voyage calculations.',
		empty_calculations:
			'Please note that the current displayed values are incomplete. To obtain the full set of values, please start the calculation process.',
		empty_second_route: 'There is currently no second route. To obtain it, please start the calculation process.',
		empty_spot_date: 'There is currently no spot date selected. To obtain it, please select one.',
		empty_dataset_code: 'There is currently no dataset selected. To obtain it, please select one.',
		no_url_calculation: 'There is currently no signedUrl. Please try again later',
		success_add_batch: 'Batch was created successfully',
		success_update_batch: 'Batch was updated successfully',
		success_delete_batch: 'Batch was deleted successfully',
		success_clone_batch: 'Batch was clones successfully',
		failed_get_batch: 'Cannot get batch detail. Please try again later',
		invalid_steps: 'Invalid steps',
		empty_freight_rates: 'There are no freight rates datasets to be displayed',
		// Estimate messages
		error_get_estimate_infos: 'An error occurred while retrieving estimate infos',
		error_get_freight_infos: 'An error occurred while retrieving freight infos',
		error_get_estimate_details: 'An error occurred while retrieving estimate details',
		success_create_estimate: 'Estimate input created successfully',
		error_create_estimate: 'An error occurred while creating estimate input',
		success_update_estimate: 'Estimate input updated successfully',
		error_update_estimate: 'An error occurred while updating estimate input',
		success_one_off_estimate: 'Estimate input ran successfully',
		error_one_off_estimate: 'An error occurred while running estimate input',
	},
	tooltips: {
		premium: 'PREMIUM = Hedge Price - Benchmark Price',
		relval: 'RELVAL = Benchmark Price / Hedge Price',
		expense_sumup_total_op_cost: 'Bunkers + Ports & Misc',
		expense_sumup_net_hire_cost: 'Vessel Hire * (Total days)',
		expense_sumup_total_cost: 'Bunkers + Ports & Misc + Total Voyage Cost + Net Hire Cost',
		owners_gross_freight: 'FIO Rate * Final Intake',
		owners_net_freight: 'FIO Rate * (1 - Com Amount) * Final Intake',
		owners_net_result: 'Net Freight - Total voyage Cost',
		owners_final_intake: 'Final intake',
		blkr_carbon_bal_name: 'Carbon bal',
		blkr_carbon_bal_formula: 'VLSFO bal + ULSFO bal + LSMGO bal',
		blkr_carbon_lad_name: 'Carbon lad',
		blkr_carbon_lad_formula: 'VLSFO lad + ULSFO lad + LSMGO lad',
		net_tce: '(((Final Intake * (FIO Rate * (1 - Com Amount))) - Total Cost) / Total Duration)',
		search: 'Search in values',
		show_all: 'Show all rows',
		show_default: 'Show default rows',
	},
	validations: {
		is_mandatory: 'is mandatory',
		is_positive: 'must be a positive number.',
		client_name: 'Client name is mandatory',
		product: 'Product is mandatory',
		vessel: 'Vessel is mandatory',
		spot_date: 'Spot date is mandatory',
		dataset: 'dataset is mandatory',
		cur_month_factor: 'Current Month Factor is mandatory',
		near_month_factor: 'Near Month Factor is mandatory',
		calc_speed: 'Calc speed is mandatory',
		batch_name: 'Batch name is mandatory',
		invalid_form: 'Form invalid !',
	},
};
