import { basePath, server } from 'src/utils/api-prefix.helper';
// constants
const baseApiUrl = `${server}${basePath}/`;
export default {
	referenceData: false,
	iconName: 'RefData',
	url: `${baseApiUrl}reference-data`,
	urlParams: '',
	path: '/reference-data',
	pageTitle: {
		list: 'Reference data',
		action: {
			create: '',
			edit: '',
		},
	},
};
