import React from 'react';
import { Moment } from 'moment';
// Function to check if a date should be disabled
export const isDateDisabled = (spotDates: Moment[], currentDate: Moment) => {
	return !spotDates.some((date) => date.isSame(currentDate, 'day'));
};

// Custom rendering function for dates
export const renderDate = (spotDates: Moment[], currentDate: Moment): React.ReactNode => {
	const isSpecialDate = spotDates.some((date) => date.isSame(currentDate, 'day'));

	if (isSpecialDate) {
		return (
			<div
				style={{
					backgroundColor: '#01bce8',
					color: '#fff',
					width: '75%',
					height: '75%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: 3,
				}}
			>
				{currentDate.date()}
			</div>
		);
	}

	return <div>{currentDate.date()}</div>;
};

export function getLastWorkingDay(date = new Date()) {
	const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
	const daysToSubtract = dayOfWeek === 1 ? 3 : dayOfWeek === 0 ? 2 : 1; // Logic for Monday and Sunday
	const lastWorkingDay = new Date(date); // Clone the date
	lastWorkingDay.setDate(date.getDate() - daysToSubtract); // Subtract the days
	return lastWorkingDay;
}

export const defaultSpotDate = getLastWorkingDay();
