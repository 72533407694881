import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { filter, findIndex } from 'lodash';
import { Link } from 'react-router-dom';

import './ExecutionPath.sass';

export default class ExecutionPath extends Component {
	constructor(props) {
		super(props);

		this.steps = [
			{ name: 'loading', label: 'Extract', onlyStructured: false },
			{ name: 'transforming', label: 'Transform', onlyStructured: true },
			{ name: 'namemapping', label: 'Columns mapping', onlyStructured: true },
			{ name: 'valuemapping', label: 'Values mapping', onlyStructured: true },
			{ name: 'filtering', label: 'Filter', onlyStructured: true },
			{ name: 'healthcheck', label: 'Healthcheck', onlyStructured: true },
			{ name: 'exporting', label: 'Load', onlyStructured: false },
		];

		if (this.props.data) {
			this.datasetGrade = this.props.data.grade; // raw or structured
			if (this.datasetGrade != 'structured') {
				// we consider only some of the steps
				this.steps = filter(this.steps, { onlyStructured: false });
			}
		}
	}

	render() {
		if (this.props.data?.shared) return '_';
		if (!this.props.value) return null;
		if (!this.datasetGrade) return null;

		const stepNumber = findIndex(this.steps, {
			name: this.props.value.last_step?.toLowerCase(),
		});

		return (
			<div className="ExecutionPath">
				{this.steps.map((s, index) => {
					const { id, last_status } = this.props.value;
					const { healthcheck_enabled } = this.props.data;
					const isBeforeCurrent = index < stepNumber;
					const isCurrent = index == stepNumber;
					const isOver = index <= stepNumber && (last_status == 'success' || last_status == 'error');
					const isWaiting = last_status == 'waiting';
					const isRunning = last_status == 'running';
					const isEditing = last_status == 'under_edit' || last_status == 'ready';
					const isSuccess = index <= stepNumber && last_status == 'success';
					const isError = last_status == 'error';
					const isActiveHealthCheck = s.name === 'healthcheck' ? healthcheck_enabled : true;

					return (
						<Tooltip key={index} title={s.label} placement="top">
							<div
								className={[
									'executionStep step',
									isWaiting ? 'waiting' : '',
									isOver ? 'finished' : '',
									isRunning ? 'running' : '',
									isCurrent ? 'current' : '',
									isEditing ? 'editing' : '',
									isSuccess || !isActiveHealthCheck ? 'success' : '',
									isError && isActiveHealthCheck ? 'error' : '',
									isBeforeCurrent ? 'before_current' : '',
								].join(' ')}
							>
								{isBeforeCurrent || isCurrent || isSuccess || index === 0 ? (
									<Link to={`/workflows/${id}/edit/${s.name}`}>{s.label[0]}</Link>
								) : (
									s.label[0]
								)}
							</div>
						</Tooltip>
					);
				})}
			</div>
		);
	}
}
