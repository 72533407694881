import React from 'react';
// ant design & Icons
import { faBuilding } from '@fortawesome/pro-light-svg-icons';
// utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';

export default {
	referenceRecord: 'company',
	referenceData: true,
	navigateByCode: true,
	iconName: faBuilding,
	url: `${referenceDataUrl}company`,
	urlParams: 'relation=[user,direct_parent, hqcity, hqcountry]',
	path: '/company',
	elasticSearchKey: 'Company',
	relatedReferences: ['location_and_group'],
	keysMatching: [
		{ key: 'hq_city', type: 'location_and_group' },
		{ key: 'hq_country', type: 'location_and_group' },
	],
	parentField: 'parent_id',
	pageTitle: {
		list: 'Companies',
		action: {
			create: 'New company',
			edit: 'Edit company',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
			type: 'textWithIcon',
			iconName: 'Pending',
			color: '#208EC3',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			valueGetter: ({ data }: any) => data.direct_parent?.name || data.direct_parent?.code,
			cellRenderer: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/${data.direct_parent.is_group ? 'company-group' : 'company'}/${data.direct_parent.code}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'HQ City',
			field: 'hq_city',
			type: 'reference',
			referenceType: 'location',
		},
		{
			headerName: 'HQ Country',
			field: 'hq_country',
			type: 'reference',
			referenceType: 'location',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
};
