import React, { FC } from 'react';
import IcoMoon from 'react-icomoon';
import iconSet from 'src/icons/selection.json';
import { Icons } from 'ui/types/enums/icons.enum';

interface ICustomIcon {
	icon: Icons | string;
	className?: string;
	width?: string | number;
	height?: string | number;
	color?: string;
}
const CustomIcon: FC<ICustomIcon> = ({ color, width, height, icon, className = '' }) => {
	const validIcon = icon in Icons ? icon : 'Default';
	return (
		<IcoMoon className={className} icon={validIcon} width={width} height={height} color={color} iconSet={iconSet} />
	);
};

export default CustomIcon;
