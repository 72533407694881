import React, { FC, useState, ReactNode } from 'react';
// Utils and Helpers
import LabelsListContext from 'src/libs/LabelsListContext';
// Interfaces
import { ILabel } from 'ui/modules/Core/types/interfaces/label.interface';

interface LabelsListProviderProps {
	children: ReactNode;
}

const LabelsListProvider: FC<LabelsListProviderProps> = ({ children }) => {
	const [labelsList, setLabelsList] = useState<{
		[recordType: string]: { values: ILabel[]; isLoading: boolean; isLoaded: boolean };
	}>({});

	const setLabelsListValues = (recordType: string, values: ILabel[]) => {
		setLabelsList((prevState) => ({
			...prevState,
			[recordType]: {
				values: values,
				isLoading: false,
				isLoaded: true,
			},
		}));
	};

	const isLabelsListLoaded = (recordType: string) => {
		return labelsList[recordType]?.isLoaded ?? false;
	};

	const getLabelsListValues = (recordType: string) => {
		return labelsList[recordType]?.values ?? [];
	};

	return (
		<LabelsListContext.Provider
			value={{
				setLabelsListValues: setLabelsListValues,
				isLabelsListLoaded: isLabelsListLoaded,
				getLabelsListValues: getLabelsListValues,
			}}
		>
			{children}
		</LabelsListContext.Provider>
	);
};

export default LabelsListProvider;
