// configs
import configCategory from 'src/ui/modules/Core/config/category.config';
// utils
import DataService from 'src/utils/DataService';

export function getCategoryService(withParams: boolean = true) {
	const params = withParams ? `?${configCategory.urlParams}` : '';
	return new DataService({
		url: `${configCategory.url}${params}`,
		urlParams: '',
		token: true,
	});
}

export function getCategoryServiceByCode(code: string) {
	return new DataService({
		url: `${configCategory.url}/${code}?${configCategory.urlOneDatasetParams}`,
		urlParams: '',
	});
}

/**
 * bulk delete category
 * @param payload
 * @returns
 */
export const bulkDeleteCategory = async (payload: { codes: string[] }) => {
	return await new DataService({
		url: `${configCategory.url}/delete`,
		urlParams: '',
	}).post(payload);
};

export const getCategoryFieldsByIdService = async (id: string) => {
	const result = await new DataService({
		url: `${configCategory.url}/${id}?relation=[category_fields]`,
		urlParams: '',
		token: true,
	}).getClean();

	return result?.data?.result?.category_fields || [];
};
