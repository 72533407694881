//constants
import { labelColumns } from 'src/ui/modules/Core/constants/label.constant';
//services
import LabelService from 'ui/modules/Core/services/label.service';
//utils
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';

export default {
	referenceData: false,
	iconName: 'Labels',
	url: `${server}${getApiPrefix()}/data-management/labels`,
	path: '/labels',
	pageTitle: {
		list: 'Labels',
		action: {
			create: 'New label',
			edit: 'Edit label',
		},
	},
	api: {
		applyLabelToResources: `${server}${getApiPrefix()}/data-management/labels/resource/`,
	},
	columns: labelColumns,
	getService: () => ({
		instance: LabelService.getInstance,
	}),
};
