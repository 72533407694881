import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Shared utils
import { capitalize } from 'lodash';
// components
import CustomIcon from 'ui/components/CustomIcon/CustomIcon';
// Ant Design
import { Space, Tooltip } from 'antd';
//constants
import { Icons } from 'ui/types/enums/icons.enum';
//configs
import config from 'config';
// Interfaces
import { IDataset } from 'ui/modules/Core/types/interfaces/dataset.interface';

interface BadgeRendererProps {
	data?: IDataset;
	value: string;
	isFilter?: boolean;
}

const BadgeRenderer: FC<BadgeRendererProps> = ({ value, data, isFilter = false }) => {
	const { t: translate } = useTranslation();

	const withName = useMemo(() => {
		return isFilter;
	}, []);

	return (
		<Tooltip
			placement="right"
			title={
				data?.shared
					? translate('model.dataset.messages.grade_of_shared')
					: capitalize(
							value
								? translate(`model.dataset.grades.${value}`)
								: translate('model.dataset.messages.no_grade')
						)
			}
		>
			<Space>
				{config.badges[value] ? (
					<>
						<CustomIcon icon={Icons.BadgeWithStar} width={20} color={config.badges[value]} />
						{withName && <span>{value}</span>}
					</>
				) : (
					value || '_'
				)}
			</Space>
		</Tooltip>
	);
};

export default BadgeRenderer;
