// utils
import { getApiPrefix, server, dataManagement } from 'src/utils/api-prefix.helper';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	url: `${serverUrl}${dataManagement}/resource-group`,
	iconName: 'ResourceGroup',
	recordid: 'id',
	urlParams: '',
	path: '/resource-groups',
	parentPage: {
		name: 'resource-group',
	},
	pageTitle: {
		list: 'Resource Group',
		action: {
			create: 'resource Group',
			edit: 'Edit resource Group',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Start At',
			field: 'start_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'End At',
			field: 'end_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Updated At',
			field: 'updated_at',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Created At',
			field: 'created_at',
			type: 'datetime',
			minWidth: 200,
		},
	],
	canView: true,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canBulkExecute: false,
	useTab: 'general',
};
