import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useLocation } from 'react-router-dom';
// Ant design & icons & icon moons
import { Icons } from 'ui/types/enums/icons.enum';
import { CaretRightOutlined } from '@ant-design/icons';
import CustomIcon from 'ui/components/CustomIcon/CustomIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuProps, Avatar, Tooltip, Space, Typography } from 'antd';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// types & interface
import { INavItemProps } from './types/interfaces/sidenav.interface';
import { IWebSocketResponse } from 'ui/modules/Core/types/interfaces/socket.interface';
import { sidenavClassNameType } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
// constants
import { sidenavKeyStorage } from 'ui/components/Nav/Sidenav/constants/sidenav.constant';
// Context
import { useUserContext } from 'src/libs/contextLib';
// Helpers and utils
import { pushNotification } from 'src/utils/PushNotification';
import { getDefaultMenuKey, getNavItems } from 'ui/components/Nav/Sidenav/helpers/navItems.helper';
import './Sidenav.sass';
// constants
const { Text } = Typography;

type MenuItem = Required<MenuProps>['items'][number];
interface SidenavProps {
	subscribeToType: any;
	unsubscribeFromType: any;
	sidenavClassName: sidenavClassNameType;
	setSidenavClassName: (value: sidenavClassNameType) => void;
}
const Sidenav: FC<SidenavProps> = ({ subscribeToType, unsubscribeFromType, sidenavClassName, setSidenavClassName }) => {
	const { isAuthenticated, handleLogout, userInitials, user, myPermissions, businessCategories } =
		useUserContext() as any;
	const history = useHistory();
	const location = useLocation();
	const { t: translate } = useTranslation();
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
	const [inlineCollapsed, setInlineCollapsed] = useState<boolean>(sidenavClassName === 'sidenav-collapsed');
	const { addToast } = useToasts();
	const items: INavItemProps[] = getNavItems(
		history,
		translate,
		user,
		isAuthenticated,
		myPermissions,
		businessCategories,
		inlineCollapsed
	);

	useLayoutEffect(() => {
		setSelectedKeys(getDefaultMenuKey(items, inlineCollapsed));
	}, []);

	useEffect(() => {
		if (location?.pathname) {
			setSelectedKeys(getDefaultMenuKey(items, inlineCollapsed));
		}
	}, [location]);

	useEffect(() => {
		const notificationSubscription = subscribeToType('system', receiveNotification);
		return () => {
			unsubscribeFromType('system', notificationSubscription);
		};
	}, []);

	// Notification handler
	const receiveNotification = (event: IWebSocketResponse) => {
		let { details } = event;
		if (typeof details === 'object') {
			details = JSON.stringify(details);
		}
		pushNotification(addToast, translate(`system.${event.type}.${event.code}`), event.type, details);
	};

	const toggleSidenav = () => {
		const className = inlineCollapsed ? 'sidenav-not-collapsed' : 'sidenav-collapsed';
		localStorage.setItem(sidenavKeyStorage, className);
		setSidenavClassName(className);
		setInlineCollapsed(!inlineCollapsed);
	};

	if (isAuthenticated) {
		return (
			<div id="side-nav-dnext" className={`side-nav-block ${inlineCollapsed ? 'collapsed' : 'not-collapsed'}`}>
				<div
					className={`cursor-pointer menu-logo d-flex justify-content-center align-item-center ${inlineCollapsed ? 'collapsed' : 'not-collapsed'}`}
					onClick={() => {
						history.push('/home');
						setSelectedKeys([]);
					}}
				>
					<CustomIcon
						icon={inlineCollapsed ? Icons.DnextMiniLogo : Icons.DnextLogo}
						width={170}
						color="white"
					/>
				</div>
				<div
					className={`arrow-toggle-sidenav d-flex justify-content-end align-item-center ${inlineCollapsed ? 'collapsed' : 'not-collapsed'}`}
					onClick={toggleSidenav}
				>
					<FontAwesomeIcon icon={inlineCollapsed ? faChevronRight : faChevronLeft} className="mr-2" />
				</div>
				<div className={`side-nav-content ${inlineCollapsed ? 'collapsed' : 'not-collapsed'}`}>
					<Menu
						mode="inline"
						inlineCollapsed={inlineCollapsed}
						items={items as MenuItem[]}
						selectedKeys={selectedKeys}
						expandIcon={({ isOpen }) =>
							!inlineCollapsed && <CaretRightOutlined rotate={isOpen ? -90 : 90} />
						}
					/>
				</div>
				<div className={`side-nav-bottom ${inlineCollapsed ? 'collapsed' : 'not-collapsed'}`}>
					<div
						className="m-2 cursor-pointer "
						onClick={() => {
							history.push('/profile');
							setSelectedKeys([]);
						}}
					>
						{inlineCollapsed ? (
							<Tooltip title={translate('pages.sidenav.user_profile')} placement="right">
								<Avatar>{userInitials}</Avatar>
							</Tooltip>
						) : (
							<Space className="open-bottom-sidenav-item user-profile d-flex align-item-center w-100">
								<Avatar>{userInitials}</Avatar>
								<Space direction="vertical" className="user-name">
									<Text
										style={{ fontSize: 13, fontWeight: 600 }}
									>{`${user.first_name} ${user.last_name}`}</Text>
									<Text className="email">{user.email}</Text>
								</Space>
							</Space>
						)}
					</div>
					{myPermissions?.api?.can_read && (
						<div
							className="m-2 cursor-pointer"
							onClick={() => {
								history.push('/documentation/api/v1');
								setSelectedKeys([]);
							}}
						>
							{inlineCollapsed ? (
								<Tooltip title={translate('pages.sidenav.api_documentation')} placement="right">
									<CustomIcon icon={Icons.Documentation} width={35} />
								</Tooltip>
							) : (
								<Space className="open-bottom-sidenav-item d-flex align-item-center w-100">
									<CustomIcon icon={Icons.Documentation} width={35} />
									<Text style={{ fontSize: 13 }}>{translate('pages.sidenav.api_documentation')}</Text>
								</Space>
							)}
						</div>
					)}
					<div
						className="m-2 cursor-pointer"
						onClick={() => {
							history.push('/support');
							setSelectedKeys([]);
						}}
					>
						{inlineCollapsed ? (
							<Tooltip title={translate('pages.sidenav.support')} placement="right">
								<CustomIcon icon={Icons.Support} width={35} />
							</Tooltip>
						) : (
							<Space className="open-bottom-sidenav-item d-flex align-item-center w-100">
								<CustomIcon icon={Icons.Support} width={35} />
								<Text style={{ fontSize: 13 }}>{translate('pages.sidenav.support')}</Text>
							</Space>
						)}
					</div>
					<div
						className="m-2 cursor-pointer"
						onClick={() => {
							handleLogout();
							setSelectedKeys([]);
						}}
					>
						{inlineCollapsed ? (
							<Tooltip title={translate('pages.sidenav.logout')} placement="right">
								<CustomIcon icon={Icons.PowerFilled} width={35} />
							</Tooltip>
						) : (
							<Space className="open-bottom-sidenav-item d-flex align-item-center w-100">
								<CustomIcon icon={Icons.PowerFilled} width={35} />
								<Text style={{ fontSize: 13 }}>{translate('pages.sidenav.logout')}</Text>
							</Space>
						)}
					</div>
				</div>
			</div>
		);
	}
	return null;
};
export default Sidenav;
