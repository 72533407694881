export default {
	name: 'Reference',
	name_plural: 'References',
	actions: {
		depth: 'Depth',
	},
	warnings: {
		warning_current_record: 'Attention: Something wrong',
	},
	success: {
		success_create: 'This record has been successfully created',
		success_update: 'This record has been successfully updated',
		sync_reference_started: 'Reference data sync has been started',
	},
	errors: {
		error_create: 'A problem occured while creating this record. Please try again later.',
		error_update: 'A problem occured while updating this record. Please try again later.',
		error_get_data: 'An error occured while retrieving data',
		error_get_related_dataset: 'An error occured while retrieving related dataset',
		error_delete: 'A problem occured while deleting this record. Please try again later.',
		error_sync_reference: 'A problem occured while synchronize the references. Please try again later.',
	},
	title: {
		retrieve_data: 'Retrieve Dataset Fields Data',
	},
};
