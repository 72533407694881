//Ant design & Icons
import { faAbacus } from '@fortawesome/pro-light-svg-icons';
//utils
import { referenceDataUrl } from 'src/utils/api-prefix.helper';

export default {
	referenceRecord: 'vegetation-index-variable',
	referenceData: true,
	navigateByCode: true,
	iconName: faAbacus,
	url: `${referenceDataUrl}vegetation-index-variable`,
	urlParams: 'relation=[user]',
	elasticSearchKey: 'VegetationIndexVariable',
	relatedReferences: [],
	keysMatching: [],
	path: '/vegetation-index-variable',
	pageTitle: {
		list: 'Vegetation index variable',
		action: {
			create: 'New vegetation index variable',
			edit: 'Edit vegetation index variable',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
			type: 'textWithIcon',
			iconName: 'Pending',
			color: '#208EC3',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
};
