export const customGradeComparator = (a: any, b: any) => {
	const order = ['Platinum', 'Gold', 'Silver', 'Bronze', 'Iron', 'None'];
	const indexA = order.indexOf(a);
	const indexB = order.indexOf(b);

	if (indexA === -1 || indexB === -1) {
		return 0;
	}
	return indexA - indexB;
};
