import comparison_view from './comparison_view';
import settings from './settings';

export default {
	title: 'TradeMatrix',
	description: 'Each set of rules will generate a new dataset that you can use in visualisations',
	comparison_view,
	settings,
	messages: {
		about_tradeflow: 'About this tradeMatrix',
		last_refresh_done: 'Last data refresh has been done {{ since }} on {{ date }}',
		no_editable_forecast: "No forecast found or the selected forecast can't be modified",
		editing_forecast_x: 'You are currently editing forecast {{ name }}',
		data_refreshed_successfully: 'Data successfully refreshed',
		refresh_started: 'trade matrix data refresh started',
		refresh_started_explanation: 'Please wait for the refresh to complete before accessing the trade matrix view',
		bulk_update_successful:
			'The update of your forecast has been successfully sent. Please wait for your grid to update',
		update_period_dataset: 'New validation has been set successfully',
		please_refresh: 'Please refresh your data since new validation dates has been defined',
		warn: 'Warn me when is available',
		rows_validation:
			'Some updates exist for the following datasets since your last validation. You can review the list below and change the date that marks the end of finalized data.',
	},
	warning: {
		no_select: 'You should select at least one row other than the disabled ones.',
	},
	success: {
		update_completed: 'Your forecast has been correctly updated',
	},
	errors: {
		tradeflow_loading_failed: 'trade matrix loading failed',
		tradeflow_loading_failed_explanation: 'Parameters missing, please go back to trade matrix parameters',
		forecast_update_failed: 'Forecast update failed',
		forecast_update_failed_explanation: 'You cannot save this forecast. Rights or access problem',
		bulk_update_invalid_file: 'Bulk update failed',
		bulk_update_invalid_file_explanation: 'File is invalid, please check column names and order',
		something_went_wrong: 'Something went wrong',
		update_failed: 'A problem has occurred when updating your forecast',
		retrieve_details: 'A problem has occurred when retrieving TradeMatrix details.',
		retrieve_snapshots: 'A problem has occurred when retrieving TradeMatrix snapshots.',
		compare_snapshots: 'A problem has occurred when comparing TradeMatrix snapshots.',
	},
	error: {
		tradeflow_loading_failed: 'trade matrix loading failed',
		tradeflow_loading_failed_explanation: 'Parameters missing, please go back to trade matrix parameters',
		forecast_update_failed: 'Forecast update failed',
		forecast_update_failed_explanation: 'You cannot save this forecast. Rights or access problem',
		bulk_update_invalid_file: 'Bulk update failed',
		bulk_update_invalid_file_explanation: 'File is invalid, please check column names and order',
		something_went_wrong: 'Something went wrong',
		update_failed: 'A problem has occurred when updating your forecast',
		missing_code: 'Code TradeMatrix is undefined',
		update_period_dataset: 'A problem occurred while updating validation date',
		download_tradeflow:
			'A problem has occurred while trying to download the tradeflow. Please check the configuration and make sure you have access to it.',
	},
	actions: {
		priority_rules: 'Priority rules',
		configuration: 'Configuration',
		back: 'Back to tradeMatrix priority rules',
		back_form: 'Back to tradeMatrix parameters',
		save: 'Save changes in current version',
		publish: 'Publish version and start a new one',
		reset_changes: 'Reset changes',
		refresh_view: 'Update view',
		download: 'Download',
		refresh_data: 'Refresh data',
		view: 'View',
		rows_validation: 'Validate selected rows',
		rows_validation_and_refresh: 'Validate selected rows and refresh data',
		retrieve_details: 'Retrieve TradeMatrix details',
		retrieve_snapshots: 'Retrieve TradeMatrix snapshots',
		compare_snapshots: 'Compare TradeMatrix snapshots',
	},
	sure_to_refresh: 'Sure to refresh?',
	download_with_intraflows: 'Do you want to download the tradeMatrix without the intraflows?',
	download_with: 'I would like to download my tradeMatrix with the following settings',
	include_intraflow: 'Include the intraflow in my tradeMatrix',
	include_row: 'Include the rest of the world in my tradeMatrix',
	unsaved_changes: 'You currently have unsaved changes in your forecast.',
	intraflows: 'Intraflows',
	intraflows_download: 'Include intraflows in the download',
	row: 'Rest of the world',
	tabs: {
		by_destination: 'Split by destination',
		by_origin: 'Split by origin',
	},
	modals: {
		publish_version: {
			title: 'Start a new forecast version',
			message:
				"You are about to start a new forecast version. The old version won't be editable anymore. Are you sure?",
		},
		name_version: {
			title: 'Name your version',
			message: 'You can define an alias for your new version. Alternatively you can also leave it blank.',
		},
		create_edit_period: {
			title: 'Define the period',
			message: 'You can leave the fields empty to create a period with no bounds',
		},
		add_dataset: {
			title: 'Add a dataset',
		},
		add_tradeflow: {
			title: 'Add a tradeMatrix',
		},
		add_forecast: {
			title: 'Add a forecast',
		},
		tradeflow_details: {
			title: 'TradeMatrix Details',
			button: 'About Data',
		},
	},
	analysis: {
		selection: {
			name: 'Selection',
			tooltip: 'Charts',
		},
		summary: {
			name: 'Summary',
			tooltip: 'Summary',
		},
		comparison: {
			name: 'Comparison',
			tooltip: 'Comparison',
		},
		sources: {
			name: 'Sources',
			tooltip: 'Details',
		},
		infos: {
			issue_origin_dest: 'Cannot load graphs without destination or origins',
			get_details: 'Click on any cell to get details',
			summary_details: 'Switch to visible in view setting for the summary',
		},
		charts: {
			yearly: {
				name: 'Yearly',
				tooltip: 'Yearly',
			},
			seasonal: {
				name: 'Seasonal',
				tooltip: 'Seasonal',
			},
			seasonal_cumul: {
				name: 'Seasonal cumulative',
				tooltip: 'Seasonal cumulative',
			},
			ytd: {
				name: 'YTD',
				tooltip: 'YTD',
			},
			range: {
				name: 'Range',
				tooltip: 'Range',
			},
			grid: {
				name: 'Grid',
				tooltip: 'Grid',
				snd_ref: 'SND - Reference',
			},
			summary: {
				name: 'Summary',
				tooltip: 'Summary',
			},
			forecast: {
				name: 'Forecast',
				tooltip: 'Forecast',
			},
		},
	},
};
