// service
import DataService from 'src/utils/DataService';
// utils
import { dataManagement, getApiPrefix, server, scriptLibrary } from 'src/utils/api-prefix.helper';
import { IScriptLibrary } from 'src/ui/modules/Core/types/interfaces/script-library.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const scriptLibraryUrl = `${serverUrl}${dataManagement}/${scriptLibrary}`;

export const getInstance = (params: string = '') => {
	return new DataService({
		url: `${scriptLibraryUrl}${params}`,
		urlParams: '',
		token: true,
	});
};

export const getAll = (params: string = '') => {
	return getInstance(params).getAll();
};

export const getOne = (id: string) => {
	return getInstance().getClean(id);
};

export const createOne = (payload: IScriptLibrary) => {
	return getInstance().create(payload);
};

export const updateOne = (id: string, payload: IScriptLibrary) => {
	return getInstance().update(id, payload);
};

export const bulkDelete = async (payload: { ids: string[] }) => {
	const params = '/delete';
	return getInstance(params).post(payload);
};

export const getScriptVersion = (id: string, versionId: string = '') => {
	return new DataService({
		url: `${scriptLibraryUrl}/${id}/versions/`,
		urlParams: '',
	}).getClean(versionId);
};

export const revertScriptVersion = (id: string, versionId: string) => {
	return new DataService({
		url: `${scriptLibraryUrl}/${id}/revert-version`,
		urlParams: '',
	}).post({ version_id: versionId });
};

export const getScriptUsage = (id: string) => {
	return new DataService({
		url: `${scriptLibraryUrl}/${id}/usage`,
		urlParams: '',
	}).post({ workflows: true });
};

const ScriptLibraryService = {
	getInstance,
	getAll,
	getOne,
	createOne,
	updateOne,
	bulkDelete,
	getScriptVersion,
	revertScriptVersion,
	getScriptUsage,
};

export default ScriptLibraryService;
